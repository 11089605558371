import React from 'react';
import Typography from '~/components/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '~/components/Paper';

export default function PaperChart({Chart, chartID, title, dataKeys, data, ...props}) {
  return (
    <Paper>
      <Typography align='center' variant="subtitle1">{title}</Typography>
      { !data && <Skeleton /> }
      { !!data && <Chart chartID={chartID} dataKeys={dataKeys} data={dataKeys.map(key => data[key])} {...props}/> }
    </Paper>
  )
}
