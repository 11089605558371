import Route from '@src/services/route'

export const singularName = 'Seguradora'
export const pluralName = 'Seguradoras'
export const permissionKeyCode = 'InsuranceBroker'

export const insuranceBrokersApi = {
  show:    new Route('/api/v1/insurance_brokers/:id'),
  update:  new Route('/api/v1/insurance_brokers/:id'),
  destroy: new Route('/api/v1/insurance_brokers/:id'),
  create:  new Route('/api/v1/insurance_brokers'),
  list:    new Route('/api/v1/insurance_brokers')
}

export const insuranceBrokersRoute = {
  list:  new Route('/sys/corretoras'),
  show:  new Route('/sys/corretoras/:id'),
  clone: new Route('/sys/corretoras/new/:id'),
  new:   new Route('/sys/corretoras/new'),
  edit:  new Route('/sys/corretoras/:id/edit')
}

export const defaultFindOneIncludes = 'user-cooperative-address.city'