
export const colorBtnNew     = 'green'
export const colorBtnEdit    = 'orange'
export const colorBtnDelete  = 'error'
export const colorBtnShow    = 'purple'
export const colorBtnSave    = 'primary'
export const colorBtnCancel  = 'secondary'

export const layoutNavBgColor = 'white'
export const layoutMenuBgColor = 'green'
