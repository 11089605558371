import Route from '@src/services/route';

export const mainRoute = new Route('/sys')

export const peopleRoute = {
  list:  new Route('/sys/people'),
  show:  new Route('/sys/people/:id'),
  clone: new Route('/sys/people/new/:id'),
  new:   new Route('/sys/people/new'),
  edit:  new Route('/sys/people/:id/edit'),
}

export const metadataRoute = {
  list:  new Route('/sys/metadata'),
  show:  new Route('/sys/metadata/:id'),
  clone: new Route('/sys/metadata/new/:id'),
  new:   new Route('/sys/metadata/new'),
  edit:  new Route('/sys/metadata/:id/edit'),
}

export const fieldsRoute = {
  list:  new Route('/sys/fields'),
  show:  new Route('/sys/fields/:id'),
  clone: new Route('/sys/fields/new/:id'),
  new:   new Route('/sys/fields/new'),
  edit:  new Route('/sys/fields/:id/edit'),
}

export const customRecordsRoute = {
  list:  new Route('/sys/r/:sys_key'),
  show:  new Route('/sys/r/:sys_key/:id'),
  clone: new Route('/sys/r/:sys_key/new/:id'),
  new:   new Route('/sys/r/:sys_key/new'),
  edit:  new Route('/sys/r/:sys_key/:id/edit'),
}

export const rolesRoute = {
  list:  new Route('/sys/roles'),
  show:  new Route('/sys/roles/:id'),
  clone: new Route('/sys/roles/new/:id'),
  new:   new Route('/sys/roles/new'),
  edit:  new Route('/sys/roles/:id/edit'),
}

export const usersRoute = {
  list:  new Route('/sys/users'),
  show:  new Route('/sys/users/:id'),
  clone: new Route('/sys/users/new/:id'),
  new:   new Route('/sys/users/new'),
  edit:  new Route('/sys/users/:id/edit'),
}

export const dashboardRoute = {
  self: new Route('/sys/dashboard')
}

export const httpStatusRoute = {
  notFound: new Route('/sys/404')
}
export const authRoute = {
  signIn:   new Route('/sys/signin'),
  callback: new Route('/sys/auth/callback'),
  signOut:  new Route('/sys/signout'),

  forgotPassword: new Route('/sys/forgot-password'),
  resetPassword:  new Route('/sys/reset-password')
}

export const citiesRoute = {
  list:  new Route('/sys/cities/'),
  show:  new Route('/sys/cities/:id'),
}


export const producersRoute = {
  list:  new Route('/sys/producers'),
  show:  new Route('/sys/producers/:id'),
  clone: new Route('/sys/producers/new/:id'),
  new:   new Route('/sys/producers/new'),
  edit:  new Route('/sys/producers/:id/edit'),
}

export const cooperativesRoute = {
  list:  new Route('/sys/admin/cooperativas'),
  show:  new Route('/sys/admin/cooperativas/:id'),
  clone: new Route('/sys/admin/cooperativas/new/:id'),
  new:   new Route('/sys/admin/cooperativas/new'),
  edit:  new Route('/sys/admin/cooperativas/:id/edit')
}