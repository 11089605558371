import Route from '@src/services/route'

export const singularName = 'Propriedade'
export const pluralName = 'Propriedades'
export const permissionKeyCode = 'Property'

export const propertiesApi = {
  show:    new Route('/api/v1/properties/:id'),
  update:  new Route('/api/v1/properties/:id'),
  destroy: new Route('/api/v1/properties/:id'),
  create:  new Route('/api/v1/properties'),
  list:    new Route('/api/v1/properties')
}

export const propertiesRoute = {
  list:  new Route('/sys/propriedades'),
  show:  new Route('/sys/propriedades/:id'),
  clone: new Route('/sys/propriedades/new/:id'),
  new:   new Route('/sys/propriedades/new'),
  edit:  new Route('/sys/propriedades/:id/edit')
}

export const defaultFindOneIncludes = 'producer.sub_cooperative-city'