/*eslint no-multi-spaces: "off"*/
/*
  Exemplo de uso:

  // Classe simples
  class Company extends RecordBase {
    static primaryKey = 'id';  // Defino que o identificador unico do registro é o atributo 'id'
    static defaultProperties = {
      ...RecordBase.defaultAttributes,
      name: '',
    };
    customCompanyMethod(){
      return name + 'custom';
    }
  };

  class User extends RecordBase {
    static defaultProperties = {
      ...RecordBase.defaultAttributes,
      name:      '',
      login:     '',
      password:  '',
      company:   null,
      companies: null,
    };

    static hasMany   = { companies: Company }; // Define que o atributo :companies é uma Collection de objetos da classe Company
    static belongsTo = { company:   Company }; // Define que o atributo :company é um objeto da classe Company
  }

  user = new User({name: 'josé', company: {name: 'Empresa principal'}, companies: [{name: 'Empresa1'},{name: 'Empresa2'}] })
*/

import { Iterable, OrderedMap } from 'immutable';
import { ExtendableRecord }     from 'extendable-record';
import humanizeString           from 'humanize-string';
import isObject                 from '@src/utils/isObject';
import isBlank                  from '@src/utils/isBlank';
import intersectionArray        from '@src/utils/intersectionArray';
import createUUID               from '@src/utils/createUUID';
import Errors                   from './Errors';

const isImmutable = arg => Iterable.isIterable(arg);
const newToken = () => createUUID();

const { keys } = Object;

function getOrConvertToImmutableObject(klass, params){
  if (isImmutable(params)) { params = params.toObject(); }

  let obj = new klass(params);

  // Para evitar que a objeto seja uma function.
  // Pode ocorrer quando a 'classe' definida não foi
  // setada de acordo com a regra das associações. (veja inicio deste arquivo)
  //
  while (typeof obj === 'function') {
    obj = new obj(params);
  }

  return obj;
}

// Esse método serve para ajustar os valores de um atributo que foi definido como HasMany.
// Deve ser passado a chave/attributo e o valor setado.
//
// Sempre irá retornar uma coleção de objetos da classe determinada para o atributo
//
function manageHasManyValue(klass, key, value){
  let objImmutable;
  let collection = OrderedMap();

  if ( value === null ) { return collection; }

  if ( Array.isArray(value) ) {
    for (const obj of Array.from(value)) {
      objImmutable = getOrConvertToImmutableObject(klass, obj);
      collection = collection.set(objImmutable.idOrToken, objImmutable);
    }
  } else if (value instanceof OrderedMap) {
    collection = value;
  } else if ( isObject(value) ) {
    objImmutable = getOrConvertToImmutableObject(klass, value);
    collection = collection.set(objImmutable.idOrToken, objImmutable);
  }
  return collection;
}

const fixedProperties = {
  _token:      null, //Uniq random token
  _is_checked: false,
  _destroy:    false,
  _created_at: null,
  _others:     {},
  errors:      null,
  id:          null,
  updated_at:  null,
  created_at:  null,
};

class RecordBase extends ExtendableRecord {
  static defaultAttributes = {};
  static get defaultProperties(){
    return {...fixedProperties, ...this.defaultAttributes };
  }

  static primaryKey = 'id';
  static belongsTo  = {};
  static hasMany    = {};
  static validates  = {};
  static get attributeNames(){return {};}

  static hummanAttributeName(attr) {
    if (attr === 'base'){
      return '';
    }
    const attrName = this.attributeNames[attr];
    return attrName || humanizeString( String(attr) );
  }

  static manyNestedAttributesParams(refer) {
    return refer.valueSeq().toArray().map( e => e.nestedParams() )
  }

  constructor(args={}) {
    if( isBlank(args) ){ args = {} }
    super();

    if (isImmutable(args)) {
      args = args.toObject();
    }
    if (Object.isFrozen(args)) { args = {...args}; }
    if (isBlank(args._created_at)) { args._created_at = new Date(); }

    args.errors = new Errors(this.constructor);

    // Sempre deve gerar um novo token ao instanciar um novo objeto
    args['_token'] = newToken();

    const argKeys = keys(args);

    // Converte os atributos belongsTo para a classe determinada
    for (const key of intersectionArray( argKeys, this.belongsToKeys )  ) {
      if (args[key] !== null) {
        args[key] = getOrConvertToImmutableObject( this._belongsTo[key], args[key] );
      }
    }

    // Converte os atributos hasMany para uma collection de objetos da classe determinada
    // Mesmo que não seja passado uma chave do hasMany sempre deve ser setado um OrderedMap.
    //
    for (const hasManyKey of Array.from( this.hasManyKeys ) ) {
      if ( argKeys.includes(hasManyKey) ){
        args[hasManyKey] = manageHasManyValue( this._hasMany[hasManyKey], hasManyKey, args[hasManyKey] );
      }else{
        args[hasManyKey] = OrderedMap();
      }
    }
    return this.updateAttributes(args);
  }

  get primaryKey(){
    return this.constructor.primaryKey;
  }

  get attributeKeys(){
    return Object.keys(this.constructor.defaultProperties);
  }

  get _belongsTo(){
    return this.constructor.belongsTo;
  }

  get _hasMany(){
    return this.constructor.hasMany;
  }

  get belongsToKeys(){
    return keys( this._belongsTo );
  }

  get hasManyKeys(){
    return keys( this._hasMany );
  }

  // Return the value of primaryKey or the random token
  //
  get idOrToken(){
    return this.recordID || this.get('_token');
  }

  // Returns the value of primaryKey
  //
  get recordID(){
    return Number( this.get(this.primaryKey) );
  }

  isBelongsTo(key){
    return this.belongsToKeys.includes(key);
  }

  isHasMany(key){
    return this.hasManyKeys.includes(key);
  }

  set(key, val) {
    if (this.isBelongsTo(key) && (val !== null)) {
      val = getOrConvertToImmutableObject( this._belongsTo[key], val);
    }else if (this.isHasMany(key)) {
      val = manageHasManyValue( this._hasMany[key], key, val);
    }

    return super.set(key, val);
  }

  updateAttributes(attrs) {
    const attributeKeys = this.attributeKeys;

    if (isImmutable(attrs)) {
      attrs = attrs.toObject();
    }

    if (!isObject(attrs)) {
      console.error('attrs deve ser um objeto');
    }

    let newRecord = this;
    const cp = {...attrs};

    for (const attr in cp) {
      if (attributeKeys.includes(attr)){
        newRecord = newRecord.set(attr, cp[attr]);
      } else {
        newRecord = newRecord.set(
          '_others',
          { ...newRecord.get('_others'), [attr]: cp[attr] }
        );
      }
    }

    return newRecord;
  }

  // Parametros que serão enviados na requisição.
  toParams() {
    return this.toObject();
  }

  nestedParams() {
    return {
      ...this.toParams(),
      id:       this.get('id') || undefined,
      _destroy: this.get('_destroy') || undefined,
    }
  }

  isNewRecord() {
    return isBlank(this.get( this.primaryKey ));
  }

  // Retornará true se for um registro que possui um ID,
  // E false caso conttrário
  //
  isPersisted() {
    return !this.isNewRecord();
  }

  // Retorna um arral com as validações definidas no contrutor
  //
  get validations(){
    return this.constructor.validates;
  }

  // método utilizado para aplicar as validações no registro.
  // Retorna um novo registro com os erros se houver.
  //
  validate(){
    const errors = this.errors.clone();
    errors.clear();

    Object.entries(this.validations).forEach( ([attr, validations] ) => {
      validations.forEach( (validation) => {
        const state = validation(this.get(attr), attr);
        if (!state.isValid){
          errors.add(attr, state.message);
        }
      });
    });

    return this.set('errors', errors);
  }

  isValid(){
    return this.get('errors').isEmpty();
  }
}

export default RecordBase;
