import React, { useEffect, useCallback } from 'react';
import {useSelector} from 'react-redux';
import OneSignal from 'react-onesignal';

import queryStringParser from '@src/utils/queryStringParser';
import Auth from '@src/services/auth';
import { authApi } from '@src/routes/api'
import { authRoute } from '@src/routes'

import FullScreenLoading from '~/components/FullScreenLoading';
import {
  Container,
  BackgroundColorAbsolute,
  ContainerLogout,
  ContentLogout,
  Logo,
} from './styles';

const authUrl = process.env.REACT_APP_AUTH_API_URL;

export default function SignOut() {
  const loading = useSelector(state => state.auth.loading);
  const signOutRoute = `${authUrl}${authApi.signOut.build()}`

  useEffect(() => {
    localStorage.clear()
    OneSignal.setSubscription(false)

    handlesignOutRequest()
  }, [])

  const handlesignOutRequest = useCallback( () => {
    const paramsUrl = {
      callback: `${window.location.origin}${authRoute.signIn.build()}`,
    }

    Auth.revokeRefreshToken().then()
    setTimeout(() => {
      window.location = `${signOutRoute}?${queryStringParser(paramsUrl)}`
    }, 500)
  },[]);

  return (
    <Container>
      <BackgroundColorAbsolute />
      <ContainerLogout>
        <ContentLogout>
          <Logo />
          {loading && <FullScreenLoading loadingText="Carregando..." />}
        </ContentLogout>
      </ContainerLogout>
    </Container>
  );
}
