import { takeLatest, all } from 'redux-saga/effects';
import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';

import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from './techinicianManagers.services';
import { techinicianManagersRoute } from './techinicianManagers.config'

/* =================================================
  REDUX TYPES
================================================= */
export const TECHNICIANS_SET_CURRENT = '@App/techinicianManagers/TECHNICIANS_SET_CURRENT';
export const TECHNICIANS_FETCH_START = '@App/techinicianManagers/TECHNICIANS_FETCH_START';
export const TECHNICIANS_FETCH_DONE  = '@App/techinicianManagers/TECHNICIANS_FETCH_DONE';
export const TECHNICIANS_FETCH_LIST_START = '@App/techinicianManagers/TECHNICIANS_FETCH_LIST_START';
export const TECHNICIANS_FETCH_LIST_DONE = '@App/techinicianManagers/TECHNICIANS_FETCH_LIST_DONE';
export const TECHNICIANS_SET_ERRORS = '@App/techinicianManagers/TECHNICIANS_SET_ERRORS';
export const CLEAR_STATE = '@App/techinicianManagers/CLEAR_STATE';
export const FIND_SUB_COOPERATIVE_REQUEST = '@App/techinicianManagers/FIND_SUB_COOPERATIVE_REQUEST';
export const DESTROY_TECHNICIANS_REQUEST = '@App/techinicianManagers/DESTROY_TECHNICIANS_REQUEST';
export const UPDATE_TECHNICIANS_REQUEST = '@App/techinicianManagers/UPDATE_TECHNICIANS_REQUEST';
export const CREATE_TECHNICIANS_REQUEST = '@App/techinicianManagers/CREATE_TECHNICIANS_REQUEST';
export const FETCH_TECHNICIANS_REQUEST  = '@App/techinicianManagers/FETCH_TECHNICIANS_REQUEST';
export const TECHNICIANS_CHANGE_LIST  = '@App/techinicianManagers/TECHNICIANS_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [TECHNICIANS_FETCH_START]: fetchStart,
    [TECHNICIANS_FETCH_DONE]:  fetchDone,
    [TECHNICIANS_FETCH_LIST_START]: fetchListStart,
    [TECHNICIANS_FETCH_LIST_DONE]: fetchListDone,
    [TECHNICIANS_SET_ERRORS]: setErrors,
    [TECHNICIANS_SET_CURRENT]: setCurrent,
    [DESTROY_TECHNICIANS_REQUEST]: initLoading,
    [CREATE_TECHNICIANS_REQUEST]: initLoading,
    [UPDATE_TECHNICIANS_REQUEST]: initLoading,
    [TECHNICIANS_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}) {
  return {
    type: FIND_SUB_COOPERATIVE_REQUEST,
    payload: { id, params }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_TECHNICIANS_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_TECHNICIANS_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_TECHNICIANS_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_TECHNICIANS_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'techinicianManagers',

  routes: {
    list: () => techinicianManagersRoute.list.build(),
    show: (id) => techinicianManagersRoute.show.build({id}),
    new:  (id) => techinicianManagersRoute.new.build({id})
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      TECHNICIANS_FETCH_START,
    FETCH_DONE:       TECHNICIANS_FETCH_DONE,
    FETCH_LIST_START: TECHNICIANS_FETCH_LIST_START,
    FETCH_LIST_DONE:  TECHNICIANS_FETCH_LIST_DONE,
    CHANGE_LIST:      TECHNICIANS_CHANGE_LIST,
    CHANGE_CURRENT:   TECHNICIANS_SET_CURRENT,
    SET_ERRORS:       TECHNICIANS_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_SUB_COOPERATIVE_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_TECHNICIANS_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_TECHNICIANS_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_TECHNICIANS_REQUEST, crudActions.createAction),
  takeLatest(FETCH_TECHNICIANS_REQUEST, crudActions.fetchListAction),
]);