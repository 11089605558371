import Request from '@src/services/request'
import queryStringParser from '@src/utils/queryStringParser'

import { spreadsheetImportsApi } from './spreadsheetImports.config'

export const findRecordRequest = (id, args={}) => {
  return Request.get( `${ spreadsheetImportsApi.show.build({id}) }?${queryStringParser(args)}` )
}

export const fetchListRecordsRequest = (args={}, requestSource) => {
  return Request.get( `${ spreadsheetImportsApi.list.build() }?${queryStringParser(args)}`, {
    cancelToken: requestSource?.token
  } )
}

export const destroyRecordRequest = (id, args={}) => {
  return Request.del( spreadsheetImportsApi.destroy.build({id}), args )
}

export const createRecordRequest = (data={}, args={}) => {
  return Request.post( `${ spreadsheetImportsApi.create.build() }?${queryStringParser(args)}`, data )
}
