'use strict';

const getValue = (item, keyValue) => {
  switch (typeof keyValue) {
    case 'string':
      return item[keyValue];
    case 'function':
      return keyValue(item);
    default:
      return item;
  }
}

const arrayToObject = (arr, keyField, keyValue=null) => {

  return Object.assign({}, ...arr.map(item => ({[item[keyField]]: getValue(item ,keyValue) })))
}

export default arrayToObject;