import Route from '@src/services/route';

export const customRecordsApi = {
  show:    new Route('/api/v1/custom_records/:sys_key/:id'),
  update:  new Route('/api/v1/custom_records/:sys_key/:id'),
  destroy: new Route('/api/v1/custom_records/:sys_key/:id'),
  create:  new Route('/api/v1/custom_records/:sys_key'),
  list:    new Route('/api/v1/custom_records/:sys_key'),
}

export const fieldsApi = {
  show:    new Route('/api/v1/fields/:id'),
  update:  new Route('/api/v1/fields/:id'),
  destroy: new Route('/api/v1/fields/:id'),
  create:  new Route('/api/v1/fields'),
  list:    new Route('/api/v1/fields'),
}

export const metadataApi = {
  show:    new Route('/api/v1/metadata/:id'),
  update:  new Route('/api/v1/metadata/:id'),
  destroy: new Route('/api/v1/metadata/:id'),
  create:  new Route('/api/v1/metadata'),
  list:    new Route('/api/v1/metadata'),
}

export const peopleApi = {
  show:    new Route('/api/v1/people/:id'),
  update:  new Route('/api/v1/people/:id'),
  destroy: new Route('/api/v1/people/:id'),
  create:  new Route('/api/v1/people'),
  list:    new Route('/api/v1/people'),
}

export const rolesApi = {
  show:    new Route('/api/v1/roles/:id'),
  update:  new Route('/api/v1/roles/:id'),
  destroy: new Route('/api/v1/roles/:id'),
  create:  new Route('/api/v1/roles'),
  list:    new Route('/api/v1/roles'),
}

export const permissionsApi = {
  show:    new Route('/api/v1/permissions/:id'),
  update:  new Route('/api/v1/permissions/:id'),
  destroy: new Route('/api/v1/permissions/:id'),
  create:  new Route('/api/v1/permissions'),
  list:    new Route('/api/v1/permissions'),
}

export const usersApi = {
  show:    new Route('/api/v1/admin/users/:id'),
  update:  new Route('/api/v1/admin/users/:id'),
  destroy: new Route('/api/v1/admin/users/:id'),
  create:  new Route('/api/v1/admin/users'),
  list:    new Route('/api/v1/admin/users'),
}

export const authApi = {
  autorize: new Route('/oauth/authorize'),
  token:    new Route('/oauth/token'),
  signOut:  new Route('/auth/sign_out'),
  revoke:   new Route('/oauth/revoke'),

  usersPassword: new Route('/api/v1/users/password')
}

export const fileUploadsApi = {
  list:   new Route('/api/v1/file_uploads'),
  update: new Route('/api/v1/file_uploads/:uuid'),
  upload: new Route('/api/v1/file_uploads'),
  delete: new Route('/api/v1/file_uploads/:uuid'),
}

export const utilsApi = {
  searchCep:  new Route('/api/v1/utils/search_cep/:cep'),
  searchCnpj: new Route('/api/v1/utils/search_cnpj/:cnpj')
}

export const statesApi = {
  search: new Route('/api/v1/states'),
  show: new Route('/api/v1/states/:id'),
}

export const citiesApi = {
  searchCities: new Route('/api/v1/cities'),
  show: new Route('/api/v1/cities/:id'),
  list: new Route('/api/v1/cities'),
}

export const producersApi = {
  show:    new Route('/api/v1/producers/:id'),
  update:  new Route('/api/v1/producers/:id'),
  destroy: new Route('/api/v1/producers/:id'),
  create:  new Route('/api/v1/producers'),
  list:    new Route('/api/v1/producers'),
}

export const cooperativesApi = {
  show:    new Route('/api/v1/admin/cooperatives/:id'),
  update:  new Route('/api/v1/admin/cooperatives/:id'),
  destroy: new Route('/api/v1/admin/cooperatives/:id'),
  create:  new Route('/api/v1/admin/cooperatives'),
  list:    new Route('/api/v1/admin/cooperatives'),
}

export const dashboardsApi = {
  totalizers: new Route('/api/v1/dashboards/totalizers'),
  stats:      new Route('/api/v1/dashboards/stats')
}