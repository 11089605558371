import createReducer from '~/store/utils/createReducer';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import Request from '@src/services/request';
import Auth from '@src/services/auth';
import { dashboardRoute } from '@src/routes'
import history from '@src/services/history';
// import { authRoute } from '@src/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const SIGN_IN_REQUEST  = '@App/auth/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS  = '@App/auth/SIGN_IN_SUCCESS';
export const SIGN_FAILURE     = '@App/auth/SIGN_FAILURE';
export const REFRESH_TOKEN    = '@App/auth/REFRESH_TOKEN';
// export const SIGN_OUT_REQUEST = '@App/auth/SIGN_OUT_REQUEST';
// export const SIGN_OUT         = '@App/auth/SIGN_OUT';

/* =================================================
  REDUX REDUCER
================================================= */
const INITIAL_STATE = {
  authorization: null,
  token: null,
  refreshToken: null,
  signed: false,
  loading: false,
};

export default createReducer(
  INITIAL_STATE,
  {
    [SIGN_IN_REQUEST]: (draft, {payload}) => {
      draft.loading = true;
      draft.authorization = payload.authorization;
    },
    [SIGN_IN_SUCCESS]: (draft, {payload}) => {
      draft.token = payload.token;
      draft.refreshToken = payload.refreshToken;
      draft.signed = true;
      draft.loading = false;
    },
    [SIGN_FAILURE]:  (draft) => {
      draft.loading = false;
    },
    [REFRESH_TOKEN]: (draft, {payload}) => {
      draft.token = payload.token;
      draft.refreshToken = payload.refreshToken;
      draft.signed = true;
    },
    // [SIGN_OUT_REQUEST]: (draft) => {
    //   draft.loading = true;
    // },
    // [SIGN_OUT]: (draft) => {
    //   draft.authorization = null;
    //   draft.loading = false;
    //   draft.refreshToken = null;
    //   draft.signed = false;
    //   draft.token = null;
    // },
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function signInRequest(authorization) {
  return {
    type: SIGN_IN_REQUEST,
    payload: { authorization }
  }
}

export function signInSuccess({ token, refreshToken }) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: { token, refreshToken }
  }
}

export function signFailure(error) {
  return {
    type: SIGN_FAILURE,
    payload: { error }
  }
}

export function refreshToken({ token, refreshToken }) {
  return {
    type: REFRESH_TOKEN,
    payload: { token, refreshToken }
  }
}

// export function signOutRequest() {
//   return {
//     type: SIGN_OUT_REQUEST
//   }
// }

// export function signOutSucess() {
//   return {
//     type: SIGN_OUT
//   }
// }


/* =================================================
  SAGA ACTIONS
================================================= */

export function* signIn({ payload }) {
  const { authorization } = payload;

  try {
    const response = yield call(Auth.signIn, {
      code: authorization
    });

    Request.setHeader(
      'Authorization',
      `Bearer ${response.data.access_token}`
    );

    yield put(signInSuccess({
      token: response.data.access_token,
      refreshToken: response.data.refresh_token
    }));

    history.push(dashboardRoute.self.path);
  } catch(err) {
    toast.error('Usuário ou senha incorretos!');
    yield put(signFailure(err));
  }
}

function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;
  if (token) {
    Request.setHeader('Authorization', `Bearer ${token}`);
  }
}

// function* signOut() {
//   try {
//     yield call(Auth.signOut);
//     yield put(signOutSucess());

//   } catch(err) {
//     toast.error('Houve um erro na autenticação!');
//     console.error(err)
//   }
// }

// function signOutSaga() {
//   history.push( authRoute.signIn.build() );
// }

export const saga = all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(SIGN_IN_REQUEST, signIn),
  // takeLatest(SIGN_OUT_REQUEST, signOut),
  // takeLatest(SIGN_OUT, signOutSaga)
]);
