import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { Path as PathParser } from 'path-parser';
// import history from '@src/services/history';
import { store } from '~/store';
import { dashboardRoute, httpStatusRoute } from '@src/routes'
import { usePermissions } from '~/hooks/usePermissions';
import { authRoute } from '@src/routes'
import getMe from '@src/utils/getMe';
import OneSignal from 'react-onesignal';
import Page from '~/components/Page'

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  redirectIfNotExact = false,
  path,
  permissionKeyCode,
  permission,
  pageTitle,
  ...rest
}) {
  const { signed } = store.getState().auth;
  const { can } = usePermissions();
  const location = useLocation()

  if (signed && isPrivate) {
    const hasPermissionToAccessRoute = can(permissionKeyCode, permission);
    if (!hasPermissionToAccessRoute && !path.startsWith(httpStatusRoute.notFound.path)) {
      const hasPermissionToAccessDashboard = can('Dashboard', 'see');

      if (hasPermissionToAccessDashboard) {
        return <Redirect to={dashboardRoute.self.path} />;
      } else {
        return <Redirect to={httpStatusRoute.notFound.path} />;
      }
    }
  }

  const { uuid } = getMe();
  setTimeout(function() {
    OneSignal.sendTag('uuid', `${uuid}`);
    OneSignal.setExternalUserId(uuid)
    OneSignal.setSubscription(true)
  }, 5000);

  const loggedOutUserTryingAccessPrivateRoute = !signed && isPrivate;
  if (loggedOutUserTryingAccessPrivateRoute) {
    OneSignal.setSubscription(false)
    return <Redirect to={authRoute.signIn.path} />;
  }

  const loggedUserTryingAccessPublicRoute = signed && !isPrivate;
  if (loggedUserTryingAccessPublicRoute) {
    return <Redirect to={dashboardRoute.self.path} />;
  }

  let pathname = location.pathname
  if (!pathname){ pathname = location.location?.pathname }

  // É necessaŕio usar o PathParser pois o path pode ter parâmetros extras
  const invalidRoute = (new PathParser(path)).test( pathname ) === null;
  const loggedUserTryingAccessUnmappedPrivateRoute = signed && isPrivate && invalidRoute;
  if (loggedUserTryingAccessUnmappedPrivateRoute && redirectIfNotExact) {
    return <Redirect to={path} />;
  }

  return <Route { ...rest } path={path} render={(props) => <Page title={pageTitle}><Component {...props} /></Page>} />;
}
