import { takeLatest, all } from 'redux-saga/effects';
import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';

import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from './sharedDocuments.services';
import { sharedDocumentsRoute } from './sharedDocuments.config'

/* =================================================
  REDUX TYPES
================================================= */
export const SHARED_DOCUMENTS_SET_CURRENT = '@App/sharedDocuments/SHARED_DOCUMENTS_SET_CURRENT';
export const SHARED_DOCUMENTS_FETCH_START = '@App/sharedDocuments/SHARED_DOCUMENTS_FETCH_START';
export const SHARED_DOCUMENTS_FETCH_DONE  = '@App/sharedDocuments/SHARED_DOCUMENTS_FETCH_DONE';
export const SHARED_DOCUMENTS_FETCH_LIST_START = '@App/sharedDocuments/SHARED_DOCUMENTS_FETCH_LIST_START';
export const SHARED_DOCUMENTS_FETCH_LIST_DONE = '@App/sharedDocuments/SHARED_DOCUMENTS_FETCH_LIST_DONE';
export const SHARED_DOCUMENTS_SET_ERRORS = '@App/sharedDocuments/SHARED_DOCUMENTS_SET_ERRORS';
export const CLEAR_STATE = '@App/sharedDocuments/CLEAR_STATE';
export const FIND_SHARED_DOCUMENT_REQUEST = '@App/sharedDocuments/FIND_SHARED_DOCUMENT_REQUEST';
export const DESTROY_SHARED_DOCUMENTS_REQUEST = '@App/sharedDocuments/DESTROY_SHARED_DOCUMENTS_REQUEST';
export const UPDATE_SHARED_DOCUMENTS_REQUEST = '@App/sharedDocuments/UPDATE_SHARED_DOCUMENTS_REQUEST';
export const CREATE_SHARED_DOCUMENTS_REQUEST = '@App/sharedDocuments/CREATE_SHARED_DOCUMENTS_REQUEST';
export const FETCH_SHARED_DOCUMENTS_REQUEST  = '@App/sharedDocuments/FETCH_SHARED_DOCUMENTS_REQUEST';
export const SHARED_DOCUMENTS_CHANGE_LIST  = '@App/sharedDocuments/SHARED_DOCUMENTS_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [SHARED_DOCUMENTS_FETCH_START]: fetchStart,
    [SHARED_DOCUMENTS_FETCH_DONE]:  fetchDone,
    [SHARED_DOCUMENTS_FETCH_LIST_START]: fetchListStart,
    [SHARED_DOCUMENTS_FETCH_LIST_DONE]: fetchListDone,
    [SHARED_DOCUMENTS_SET_ERRORS]: setErrors,
    [SHARED_DOCUMENTS_SET_CURRENT]: setCurrent,
    [DESTROY_SHARED_DOCUMENTS_REQUEST]: initLoading,
    [CREATE_SHARED_DOCUMENTS_REQUEST]: initLoading,
    [UPDATE_SHARED_DOCUMENTS_REQUEST]: initLoading,
    [SHARED_DOCUMENTS_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}) {
  return {
    type: FIND_SHARED_DOCUMENT_REQUEST,
    payload: { id, params }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_SHARED_DOCUMENTS_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_SHARED_DOCUMENTS_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_SHARED_DOCUMENTS_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_SHARED_DOCUMENTS_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'sharedDocuments',

  routes: {
    list: () => sharedDocumentsRoute.list.build(),
    show: (id) => sharedDocumentsRoute.show.build({id}),
    new:  (id) => sharedDocumentsRoute.new.build({id})
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      SHARED_DOCUMENTS_FETCH_START,
    FETCH_DONE:       SHARED_DOCUMENTS_FETCH_DONE,
    FETCH_LIST_START: SHARED_DOCUMENTS_FETCH_LIST_START,
    FETCH_LIST_DONE:  SHARED_DOCUMENTS_FETCH_LIST_DONE,
    CHANGE_LIST:      SHARED_DOCUMENTS_CHANGE_LIST,
    CHANGE_CURRENT:   SHARED_DOCUMENTS_SET_CURRENT,
    SET_ERRORS:       SHARED_DOCUMENTS_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_SHARED_DOCUMENT_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_SHARED_DOCUMENTS_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_SHARED_DOCUMENTS_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_SHARED_DOCUMENTS_REQUEST, crudActions.createAction),
  takeLatest(FETCH_SHARED_DOCUMENTS_REQUEST, crudActions.fetchListAction),
]);