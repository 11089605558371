import RecordBase from './Base';
import ArchiveRecord from './ArchiveRecord';
import userSchema from './schema/userSchema';

export default class UserRecord extends RecordBase {
  static defaultAttributes = userSchema.defaultAttributes;
  static get attributeNames() { return userSchema.attributeNames }
  static _attributeNames = null;

  static belongsTo = { avatar: ArchiveRecord };

  toParams() {
    const {
      name,
      email,
      password,
      access_type,
      password_confirmation,
    } = this.toObject();

    return {
      data:{
        attributes:{
          name,
          email,
          access_type,
          password: password || undefined,
          password_confirmation: password_confirmation || undefined,

          avatar_id:          this.get('avatar')?.get('id') || null
        }
      }
    };
  }

  nestedParams() {
    return {
      id:                    this.get('id') || undefined,
      password:              this.get('password') || undefined,
      password_confirmation: this.get('password_confirmation')
    };
  }
}
