import React from 'react';

import Typography from '@material-ui/core/Typography';
import imgLogo from '@src/assets/images/report-image.png'
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';

import {
  Header, Image, Content, Text, Icon
} from './styles'

function HeaderPrint() {
  return (
    <Header className="only-print">
      <Image variant="square" src={imgLogo} />
      <Content>
        <Typography variant="subtitle1" component="h1">
          <strong>FRACEL SEGUROS</strong>
        </Typography>
        <Text component="a" href="https://goo.gl/maps/Dy5dAeDNmvniJowY9" target="_blank" rel="noopener">
          <Icon><RoomIcon /></Icon>
          R. Rio de Janeiro, 253D - Centro, Chapecó - SC, 89801-213
        </Text>
        <Text component="a" href="tel:+554933192719">
          <Icon><PhoneIcon /></Icon>
          (49) 3319-2719
        </Text>
        <Text component="a" href="mailto:fracel@fracelseguros.com.br">
          <Icon><MailOutlineIcon /></Icon>
          fracel@fracelseguros.com.br
        </Text>
      </Content>
      <Content style={{textAlign: 'right', marginLeft: 'auto'}}>
        <Text component="a" href="https://fracelseguros.com.br" target="_blank" rel="noopener">
          <Icon><LanguageIcon /></Icon>
          https://fracelseguros.com.br
        </Text>
        <Text component="a" href="https://www.instagram.com/fracelcorretora" target="_blank" rel="noopener">
          <Icon><InstagramIcon /></Icon>
          @fracelcorretora
        </Text>
      </Content>
    </Header>
  );
}

export default React.memo(HeaderPrint)