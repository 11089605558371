import SchemaBuilder from './SchemaBuilder';
import userAccessTypeEnum from '@src/enums/userAccessTypeEnum';

const disableList = { tableView: false, hidden: true, isFilter:  false }

const columns = {
  id:                    { type: 'integer',  title: 'ID',             width: 80                              },
  name:                  { type: 'string',   title: 'Nome',           width: 180                             },
  email:                 { type: 'string',   title: 'E-mail',         width: 180                             },
  access_type:           { type: 'enum',     title: 'Tipo de Acesso', width: 200, _enum_: userAccessTypeEnum },
  password:              { type: 'string',   title: 'Senha de Acesso',         ...disableList                },
  password_confirmation: { type: 'string',   title: 'Confirmação da senha',    ...disableList                },
  avatar:                { type: 'file',     title: 'Foto',                    ...disableList                },
  created_at:            { type: 'datetime', title: 'Criado em'                                              },
  disabled_at:           { type: 'datetime', title: 'Status',         width: 180, hidden: true               }
};

const userSchema = new SchemaBuilder( columns );

export default userSchema;
