import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

export const Header = styled.header`
  @media print {
    display: flex;
  }
  min-height: 160px;
  border-bottom: 1px #907c7c;
  margin-bottom: 20px;
  border-bottom-style: dashed;
  padding-bottom: 20px;
  a {
    text-decoration: none
  }
`;

const imageSize = 140;
export const Image = styled(Avatar)`
  height: auto;
  width:  ${imageSize}px;
  align-self: center;
`;

export const Content = styled.div`
  padding-left: 20px
`;

export const Icon = styled.div`
  margin-right: 6px;
  display: inline-block;
`;
export const Text = styled(Typography)`
  display: flex
`;