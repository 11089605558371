import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import FullScreenLoading from '~/components/FullScreenLoading';
import { signInRequest } from '~/store/modules/auth';

import isPresent from '@src/utils/isPresent';

import {
  Container,
  BackgroundColorAbsolute,
  ContainerLogin,
  ContentLogin,
  Logo,
} from './styles';

export default function SignIn({ location }) {
  const [code, setCode] = useState(null);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    setCode(params.get('code'));
  }, [location?.search])

  useEffect(() => {
    if (isPresent(code)) {
      dispatch(signInRequest(code));
    }
  }, [code])

  return (
    <Container>
      <BackgroundColorAbsolute />
      <ContainerLogin>
        <ContentLogin>
          <Logo />
          {loading && <FullScreenLoading loadingText="Carregando..." />}
        </ContentLogin>
      </ContainerLogin>
    </Container>
  );
}
