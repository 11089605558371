import createReducer from '~/store/utils/createReducer';
import { toast } from 'react-toastify';
import { takeLatest, call, put, all } from 'redux-saga/effects';

// import { SIGN_OUT } from '~/store/modules/auth';
import Request from '@src/services/request';
import { usersApi } from '@src/routes/api';
import isEmpty from '@src/utils/isEmpty';
import deserialize from '@src/utils/deserialize';

const includeQuery = 'include=user_roles.role.role_permissions.permission';

/* =================================================
  REDUX TYPES
================================================= */
export const CLEAR_STATE          = '@App/user/CLEAR_STATE';
export const CHANGE_USER_FIELD = '@App/user/CHANGE_USER_FIELD';
export const FIND_USER_PROFILE_REQUEST = '@App/user/FIND_USER_PROFILE_REQUEST';
export const FIND_USER_PROFILE_SUCCESS = '@App/user/FIND_USER_PROFILE_SUCCESS';
export const FIND_USER_PROFILE_FAILURE = '@App/user/FIND_USER_PROFILE_FAILURE';
export const EDIT_USER_PROFILE_REQUEST = '@App/user/EDIT_USER_PROFILE_REQUEST';
export const EDIT_USER_PROFILE_SUCCESS = '@App/user/EDIT_USER_PROFILE_SUCCESS';
export const EDIT_USER_PROFILE_FAILURE = '@App/user/EDIT_USER_PROFILE_FAILURE';

/* =================================================
  REDUX REDUCER
================================================= */
const INITIAL_STATE = {
  data: {},
  errors: {},
  loading: false,
  searchingRecord: true,
};

const failureReducerFn = (draft, {payload}) => {
  draft.errors = payload ? payload.errors : {};
  draft.loading = false;
  draft.searchingRecord = false;
}

const successReducerFn = (draft, {payload}) => {
  draft.data = payload.user;
  draft.loading = false;
  draft.searchingRecord = false;
}

const clearReducerFn = (draft) => {
  draft.data = {};
  draft.loading = false;
  draft.searchingRecord = true;
  draft.errors = {};
}

export default createReducer(
  INITIAL_STATE,
  {
    [EDIT_USER_PROFILE_REQUEST]: (draft) => { draft.loading = true; },
    [EDIT_USER_PROFILE_FAILURE]: failureReducerFn,
    [FIND_USER_PROFILE_FAILURE]: failureReducerFn,
    [EDIT_USER_PROFILE_SUCCESS]: successReducerFn,
    [FIND_USER_PROFILE_SUCCESS]: successReducerFn,
    // [SIGN_OUT]: clearReducerFn,
    [CLEAR_STATE]: clearReducerFn,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function findUserProfileRequest(id) {
  return {
    type: FIND_USER_PROFILE_REQUEST,
    payload: { id }
  }
}

export function findUserProfileSuccess(user) {
  return {
    type: FIND_USER_PROFILE_SUCCESS,
    payload: { user }
  }
}

export function findUserProfileFailure(errors) {
  return {
    type: FIND_USER_PROFILE_FAILURE,
    payload: { errors }
  }
}

export function clearState() {
  return {
    type: CLEAR_STATE
  }
}

export function editUserProfileRequest(id, data) {
  return {
    type: EDIT_USER_PROFILE_REQUEST,
    payload: {id, data}
  }
}

export function editUserProfileSuccess(user) {
  return {
    type: EDIT_USER_PROFILE_SUCCESS,
    payload: user
  }
}

export function editUserProfileFailure(errors) {
  return {
    type: EDIT_USER_PROFILE_FAILURE,
    payload: errors,
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
function formatPermission(includedData) {
  const userPermissions = {};
  const rolePermissions = includedData.filter(
    include => include.type === 'role_permissions'
  );
  const permissions = includedData.filter(
    include => include.type === 'permissions'
  );

  permissions?.forEach(permission => {
    const permissionKeyCode = permission.attributes?.key_code;
    const [formattedRolePermission = {}] =
      rolePermissions?.filter(rolePermission => (
        Number(rolePermission.attributes?.permission_id) === Number(permission.id)
      ))
      .map(rolePermission => (
        { id: rolePermission.id, ...rolePermission.attributes }
      ));

    userPermissions[permissionKeyCode] = formattedRolePermission;
  });

  return userPermissions;
}

function* updateRecord(response) {
  const responseData = response.data;
  const user = {};

  if (responseData?.data) {
    let formattedUser = yield deserialize(responseData);
    const included = response.data?.included || [];

    Object.assign(user, {
      id: formattedUser.id,
      name: formattedUser.name,
      email: formattedUser.email,
      uuid: formattedUser.uuid,
      access_type: formattedUser.access_type,
      permissions: formatPermission(included)
    });
  }

  yield put(findUserProfileSuccess(user));
}

export function* findUserProfile() {
  try{
    const response = yield call(
      Request.get,
      `/api/v1/users/me?${includeQuery}`
    );

    yield updateRecord(response);
  } catch(err) {
    toast.error('Ocorreu um problema ao carregar as informações do usuário!');
    yield put(findUserProfileFailure());
  }
}

export function* editUserProfile({ payload }){
  try{
    const {id, data: reqData} = payload;
    if(isEmpty(reqData.data))
      return;

    let dataToPost = {};
    if (reqData.data.password.length > 0) {
      dataToPost = {
        data:{
          attributes: {
            ...reqData.data,
            password_confirmation: reqData.data['password']
          }
        }
      }
    } else {
      dataToPost = {
        data: {
          attributes: {
            name: reqData.data['name'],
            email: reqData.data['email']
          }
        }
      }
    }

    const response = yield call(
      Request.put,
      `${usersApi.update.build({id})}?${includeQuery}`,
      dataToPost
    );
    toast.success('Perfil alterado com sucesso!');

    yield updateRecord(response)
  } catch(err) {
    yield setError(
      err,
      editUserProfileFailure,
      'Ocorreu um problema ao alterar as informações do usuário. Tente novamente mais tarde!'
    );
  }
}

function* setError(err, action, messageError) {
  let errors = {};

  if (err.status === Request.HTTP_STATUS.UNPROCESSABLE_ENTITY) {
    err.errors.forEach(error => {
      errors[error.field] = error.title;
    });
  } else {
    toast.error(messageError);
  }

  yield put(action(errors));
}

export const saga = all([
  takeLatest(FIND_USER_PROFILE_REQUEST, findUserProfile),
  takeLatest(EDIT_USER_PROFILE_REQUEST, editUserProfile),
]);