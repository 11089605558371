import RecordBase from './Base';
import genericSchema from './schema/genericSchema';

export default class GenericRecord extends RecordBase {
  static defaultAttributes = genericSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() {
    return genericSchema.attributeNames;
  }

  toParams() {
    const { id, name } = this.toObject();

    return { data: { attributes: { id, name } } };
  }

  nestedParams() {
    return {
      id:              this.get('id') || undefined,
      name:            this.get('name'),
      _destroy:        this.get('_destroy') || undefined
    };
  }
}
