import { takeLatest, all } from 'redux-saga/effects';
import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';

import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from './properties.services';
import { propertiesRoute } from './properties.config'

/* =================================================
  REDUX TYPES
================================================= */
export const PROPERTIES_SET_CURRENT = '@App/properties/PROPERTIES_SET_CURRENT';
export const PROPERTIES_FETCH_START = '@App/properties/PROPERTIES_FETCH_START';
export const PROPERTIES_FETCH_DONE  = '@App/properties/PROPERTIES_FETCH_DONE';
export const PROPERTIES_FETCH_LIST_START = '@App/properties/PROPERTIES_FETCH_LIST_START';
export const PROPERTIES_FETCH_LIST_DONE = '@App/properties/PROPERTIES_FETCH_LIST_DONE';
export const PROPERTIES_SET_ERRORS = '@App/properties/PROPERTIES_SET_ERRORS';
export const CLEAR_STATE = '@App/properties/CLEAR_STATE';
export const FIND_PROPERTY_REQUEST = '@App/properties/FIND_PROPERTY_REQUEST';
export const DESTROY_PROPERTIES_REQUEST = '@App/properties/DESTROY_PROPERTIES_REQUEST';
export const UPDATE_PROPERTIES_REQUEST = '@App/properties/UPDATE_PROPERTIES_REQUEST';
export const CREATE_PROPERTIES_REQUEST = '@App/properties/CREATE_PROPERTIES_REQUEST';
export const FETCH_PROPERTIES_REQUEST  = '@App/properties/FETCH_PROPERTIES_REQUEST';
export const PROPERTIES_CHANGE_LIST  = '@App/properties/PROPERTIES_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [PROPERTIES_FETCH_START]: fetchStart,
    [PROPERTIES_FETCH_DONE]:  fetchDone,
    [PROPERTIES_FETCH_LIST_START]: fetchListStart,
    [PROPERTIES_FETCH_LIST_DONE]: fetchListDone,
    [PROPERTIES_SET_ERRORS]: setErrors,
    [PROPERTIES_SET_CURRENT]: setCurrent,
    [DESTROY_PROPERTIES_REQUEST]: initLoading,
    [CREATE_PROPERTIES_REQUEST]: initLoading,
    [UPDATE_PROPERTIES_REQUEST]: initLoading,
    [PROPERTIES_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}) {
  return {
    type: FIND_PROPERTY_REQUEST,
    payload: { id, params }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_PROPERTIES_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_PROPERTIES_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_PROPERTIES_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_PROPERTIES_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'properties',

  routes: {
    list: () => propertiesRoute.list.build(),
    show: (id) => propertiesRoute.show.build({id}),
    new:  (id) => propertiesRoute.new.build({id})
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      PROPERTIES_FETCH_START,
    FETCH_DONE:       PROPERTIES_FETCH_DONE,
    FETCH_LIST_START: PROPERTIES_FETCH_LIST_START,
    FETCH_LIST_DONE:  PROPERTIES_FETCH_LIST_DONE,
    CHANGE_LIST:      PROPERTIES_CHANGE_LIST,
    CHANGE_CURRENT:   PROPERTIES_SET_CURRENT,
    SET_ERRORS:       PROPERTIES_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_PROPERTY_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_PROPERTIES_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_PROPERTIES_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_PROPERTIES_REQUEST, crudActions.createAction),
  takeLatest(FETCH_PROPERTIES_REQUEST, crudActions.fetchListAction),
]);