import React, { useCallback } from 'react';

import { Link as RouteLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Typography from '~/components/Typography';
import { useBreadcrumbs } from '~/hooks/useBreadcrumbs';

const useStyles = makeStyles({
  typography: {
    fontWeight: 600
  },
  link: {
    color:      '#898886',
    fontSize:   '1rem',
    fontWeight: 300
  },
  breadcrumbs: {
    marginLeft: '30px'
  }
});

function BreadcrumbsComponent() {
  const classes = useStyles();
  const { breadcrumbs } = useBreadcrumbs();

  const handleClick = useCallback((event, breadcrumb) => {
    if (breadcrumb.onClick) {
      breadcrumb.onClick(event, breadcrumb);
      return;
    }
  }, []);

  if (!breadcrumbs?.length) return <></>;

  return (
    <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb" separator="/">
      {breadcrumbs.map((breadcrumb, idx) => {
        const isLastBreadcrumb = idx === (breadcrumbs.length - 1);

        if (isLastBreadcrumb) {
          return <Typography className={classes.typography} color='primary' key={idx}>{breadcrumb.text}</Typography>;
        }

        return (
          <Link
            className={classes.link}
            component={RouteLink}
            key={idx}
            to={breadcrumb.path}
            onClick={(e) => handleClick(e, breadcrumb)}
          >
            {breadcrumb.text}
          </Link>
        );
      })}
    </Breadcrumbs>
  )
}

export default BreadcrumbsComponent