import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import drawerMenuItems, { drawerMenuAvatar } from '@src/routes/drawerMenu';
import DefaultOriginRouteComponent from '@src/routes/DefaultOriginRoute'
import DrawerMenu from '~/components/DrawerMenu';
import ProfileMenu from '~/components/ProfileMenu';
import { PermissionsProvider } from './hooks/usePermissions';
import { MetadataProvider } from './hooks/useMetadata';
import { ProfileDrawerProvider } from './contexts/ProfileDrawerContext';
import { DrawerMenuProvider } from './contexts/DrawerMenuContext';
import { BreadcrumbsProvider } from './hooks/useBreadcrumbs';

import {
  httpStatusRoute,
  dashboardRoute,
  peopleRoute,
  citiesRoute,
  metadataRoute,
  fieldsRoute,
  customRecordsRoute,
  usersRoute,
  rolesRoute,
  authRoute,
  producersRoute,
  cooperativesRoute
} from '@src/routes'

const DashboardComponent = lazy(() => import ('~/pages/Dashboard') )
const NotFoundComponent = lazy(() => import ('~/pages/NotFound') )
const PeopleComponent = lazy(() => import ('~/pages/People') )
const ProducersComponent = lazy(() => import ('~/pages/Producers') )
const CooperativesComponent = lazy(() => import ('~/pages/Cooperatives') )
const CitiesComponent = lazy(() => import ('~/pages/Cities') )
const MetadataComponent = lazy(() => import ('~/pages/Metadata') )
const FieldsComponent = lazy(() => import ('~/pages/Fields') )
const CustomRecordsComponent = lazy(() => import ('~/pages/CustomRecords') )
const UsersComponent = lazy(() => import ('~/pages/Users') )
const SignOut = lazy(() => import ('~/pages/SignOut') )
const SignIn = lazy(() => import ('~/pages/SignIn') )
const AuthRedirect = lazy(() => import ('~/pages/Auth') )
const RolesComponent = lazy(() => import ('~/pages/Roles') )
const ExamplesComponent = lazy(() => import ('~/pages/Examples') )

import { subCooperativesRoute } from '~/modules/subCooperatives/subCooperatives.config'
const SubCooperativesComponent = lazy(() => import ('~/modules/subCooperatives/subCooperatives.pages') )

import { techniciansRoute } from '~/modules/technicians/technicians.config'
const TechniciansComponent = lazy(() => import ('~/modules/technicians/technicians.pages') )

import { techinicianManagersRoute } from '~/modules/techinicianManagers/techinicianManagers.config'
const TechinicianManagersComponent = lazy(() => import ('~/modules/techinicianManagers/techinicianManagers.pages') )

import { insuranceBrokersRoute } from '~/modules/insuranceBrokers/insuranceBrokers.config'
const InsuranceBrokersComponent = lazy(() => import ('~/modules/insuranceBrokers/insuranceBrokers.pages') )

import { propertiesRoute } from '~/modules/properties/properties.config'
const PropertiesComponent = lazy(() => import ('~/modules/properties/properties.pages') )

import { sharedDocumentsRoute } from '~/modules/sharedDocuments/sharedDocuments.config'
const SharedDocumentComponent = lazy(() => import ('~/modules/sharedDocuments/sharedDocuments.pages') )

import { spreadsheetImportsRoute } from '~/modules/spreadsheetImports/spreadsheetImports.config'
const SpreadsheetImportsComponent = lazy(() => import ('~/modules/spreadsheetImports/spreadsheetImports.pages') )

function AppRoutes() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path={dashboardRoute.self.path} component={DashboardComponent} exact />
        <Route path={peopleRoute.list.path} component={PeopleComponent} />
        <Route path={customRecordsRoute.list.path} component={CustomRecordsComponent} />
        <Route path={rolesRoute.list.path} component={RolesComponent} />
        <Route path={usersRoute.list.path} component={UsersComponent} />

        <Route path={producersRoute.list.path} component={ProducersComponent} />
        <Route path={cooperativesRoute.list.path} component={CooperativesComponent} />
        <Route path={subCooperativesRoute.list.path} component={SubCooperativesComponent} />
        <Route path={techniciansRoute.list.path} component={TechniciansComponent} />
        <Route path={techinicianManagersRoute.list.path} component={TechinicianManagersComponent} />
        <Route path={insuranceBrokersRoute.list.path} component={InsuranceBrokersComponent} />
        <Route path={propertiesRoute.list.path} component={PropertiesComponent} />
        <Route path={sharedDocumentsRoute.list.path} component={SharedDocumentComponent} />
        <Route path={spreadsheetImportsRoute.list.path} component={SpreadsheetImportsComponent} />

        {/* MASTER */}
        <Route path={metadataRoute.list.path} component={MetadataComponent} />
        <Route path={fieldsRoute.list.path} component={FieldsComponent} />
        <Route path={'/sys/components-example'} component={ExamplesComponent} exact />
        <Route path={citiesRoute.list.path} component={CitiesComponent} />

        <Route path={authRoute.signOut.path} component={SignOut} exact />
        {/*As rotas de Login e Logout precisam estar nas rotas privadas para usar nos casos de refazer o login*/}
        <Route path={authRoute.signIn.path} component={AuthRedirect} exact />
        <Route path={authRoute.callback.path} component={SignIn} exact />

        <Route path={httpStatusRoute.notFound.path} component={NotFoundComponent} />
        <Route component={DefaultOriginRouteComponent} />
      </Switch>
    </Suspense>
  );
}

export default function PrivateRoutes() {
  return(
    <DrawerMenuProvider>
      <BreadcrumbsProvider>
        <PermissionsProvider>
          <MetadataProvider>
            <ProfileDrawerProvider>
              <DrawerMenu avatar={drawerMenuAvatar} items={drawerMenuItems} />
              <ProfileMenu />
              <AppRoutes />
            </ProfileDrawerProvider>
          </MetadataProvider>
        </PermissionsProvider>
      </BreadcrumbsProvider>
    </DrawerMenuProvider>
  );
}
