import SchemaBuilder from './SchemaBuilder';

const columns = {
  id:              { type: 'string', title: 'Id'                       },
  city:            { type: 'string', title: 'Cidade'                   },
  state:           { type: 'string', title: 'Estado'                   },
  name:            { type: 'string', title: 'Nome',        default: '' },
  district:        { type: 'string', title: 'Bairro',      default: '' },
  street:          { type: 'string', title: 'Logradouro',  default: '' },
  building_number: { type: 'string', title: 'Número',      default: '' },
  complement:      { type: 'string', title: 'Complemento', default: '' },
  reference:       { type: 'string', title: 'Referência',  default: '' },
  zip_code:        { type: 'string', title: 'CEP',         default: '' },
};

const addressSchema = new SchemaBuilder( columns );

export default addressSchema;