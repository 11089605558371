import SchemaBuilder from './SchemaBuilder';

import { cityAsyncFetch, cityAsyncShow } from '@src/services/selectFetch/citiesFetch';

const hiddenList = {tableView: false, hidden: true, isFilter:  false}

const columns = {
  id:            { type: 'integer',  width: 80,  default: null, title: 'ID'                               },
  name:          { type: 'string',   width: 180, default: '',   title: 'Nome'                             },
  registration:  { type: 'string',   width: 170, default: '',   title: 'Matrícula'                        },
  taxid:         { type: 'string',   width: 200, default: '',   title: 'CPF/CNPJ'                         },
  rg:            { type: 'string',   width: 180, default: '',   title: 'RG/IE',              hidden: true },
  legal_name:    { type: 'string',   width: 200, default: '',   title: 'Razão Social'                     },
  email:         { type: 'string',   width: 250, default: '',   title: 'Email'                            },
  phone:         { type: 'string',   width: 170, default: '',   title: 'Telefone'                         },
  cell_phone:    { type: 'string',   width: 170, default: '',   title: 'Celular'                          },
  birth_date:    { type: 'date',     width: 150, default: '',   title: 'Data de Nascimento', hidden: true },
  created_at:    { type: 'datetime', width: 200, default: '',   title: 'Criado em',          hidden: true },
  share_capital: { type: 'currency', width: 200, default: '',   title: 'Capital Social',     hidden: true },

  is_legal:        { type: 'boolean', width: 200, default: false, title: 'Jurídica',  hidden: true },
  active:          { type: 'boolean', width: 200, default: true,  title: 'Ativo',     hidden: true },
  address:         { type: 'object',  title: 'Endereço',              ...hiddenList },
  user:            { type: 'object',  title: 'Usuário',              ...hiddenList },
  sub_cooperative: { type: 'object',  title: 'Sub Cooperativa', ...hiddenList },

  attachments:  { type: 'array',   title: 'Arquivos', default: [], ...hiddenList },
  observation:   {
    type: 'string',
    width: 240, default: '',
    title: 'Observação',
    wordWrapEnabled: true,
    hidden: true
  },
  city_id: {
    type:     'async',
    title:    'Cidade',
    tableView: false,
    filterOptions: {
      asyncFetch: cityAsyncFetch,
      asyncShow:  cityAsyncShow
    }
  }
};

const producerSchema = new SchemaBuilder( columns );

export default producerSchema;