import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { findUserProfileRequest } from '~/store/modules/userProfile';
import getMe from '@src/utils/getMe'
import AnimatedLoadingFull from '~/components/AnimatedLoadingFull';

import HeaderPrint from '~/containers/HeaderPrint'

import PublicRoutes  from './publicRoute';
import PrivateRoutes from './privateRoute';

const MainComponent = styled.main`
  display: flex;
`;

const PrivatePages = ({loading, children}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const currentUser = getMe();
    if (currentUser) {
      dispatch(findUserProfileRequest(currentUser.id));
    }
  }, []);

  if (loading) {
    return <AnimatedLoadingFull />
  }

  return children
}

function Main({ signed, loading }) {
  if (!signed) {
    return (
      <MainComponent>
        <PublicRoutes />
      </MainComponent>
    );
  }

  return (
    <PrivatePages loading={loading}>
      <HeaderPrint />
      <MainComponent>
        <PrivateRoutes />
      </MainComponent>
    </PrivatePages>
  )
}

const mapStateToProps = state => ({
  signed: state.auth.signed,
  loading: state.userProfile.searchingRecord,
});

export default connect(mapStateToProps)(Main);
