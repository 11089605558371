import React, { createContext } from 'react'
import isPresent from '@src/utils/isPresent'

const PageTitleContext = createContext({})

const defaultPrefix = process.env.REACT_APP_TITLE

var prefix = defaultPrefix
var title  = ''
var sufix  = ''
var timeoutSetTitle = null
var setValueTimeout = null

function changeTitle(){
  clearTimeout(timeoutSetTitle)
  timeoutSetTitle = setTimeout( () => {
    const fullTitle = [prefix, title, sufix].filter(isPresent)
    if( fullTitle.length ){
      document.title = fullTitle.join(' | ')
    }else{
      document.title = defaultPrefix
    }
  }, 200)
}

function setPrefix(value){
  prefix = value

  clearTimeout(setValueTimeout)
  setValueTimeout = setTimeout(changeTitle, 200)
}

function setTitle(value){
  title = value
  clearTimeout(setValueTimeout)
  setValueTimeout = setTimeout(changeTitle, 200)
}

function setSufix(value){
  sufix = value
  clearTimeout(setValueTimeout)
  setValueTimeout = setTimeout(changeTitle, 200)
}

function resetToDefault(){
  prefix = defaultPrefix
  title  = ''
  sufix  = ''
}

export function PageTitleContextProvider({ children }) {
  return (
    <PageTitleContext.Provider value={{ setPrefix, setTitle, setSufix, resetToDefault }}>
      {children}
    </PageTitleContext.Provider>
  )
}

export default PageTitleContext
