import React from 'react';

import NotFoundImg from '@src/assets/images/not-found.png';

import { Container } from './styles';

function NotFound() {
  return (
    <Container className="printable" >
      <img src={NotFoundImg} alt="Página não encontrada"/>
      <h1>Página não encontrada :(</h1>
      <h4>para mais informações contate o Administrador</h4>
    </Container>
  );
}

export default NotFound;
