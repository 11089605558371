import Request from '@src/services/request';

export const cooperativesFetch = (inputValue, page, doneCallback) => {
  return Request.get(`/api/v1/search/cooperatives?qs=${inputValue}&page=${page}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data.map( e => ({
          id: e.id,
          name: e.name,
        }))
      }
    )
  })
  .catch(err => err);
}

export const cooperativesShow =  (id) => {
  return Request.get(`/api/v1/search/cooperatives/${id}`).then(resp => {
    if(resp.data && resp.data.data){
      return resp.data.data
    }

    return { id: null, name: 'Não localizado' };
  })
  .catch(err => err);
}