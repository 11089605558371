import RecordBase from './Base';
import producerSchema from './schema/producerSchema';
import ArchiveRecord from './ArchiveRecord';
import UserRecord from './UserRecord';
import AddressRecord from './AddressRecord';
import GenericRecord from './GenericRecord';

export const pluralName = 'Produtores';
export const singularName = 'Produtor';

export default class ProducerRecord extends RecordBase {
  static defaultAttributes = producerSchema.defaultAttributes;
  static get attributeNames() { return producerSchema.attributeNames }
  static _attributeNames = null;

  static hasMany = { attachments: ArchiveRecord };
  static belongsTo = {
    user: UserRecord,
    address: AddressRecord,
    sub_cooperative: GenericRecord
  };

  toParams(extra = {}) {
    return {
      data:{
        attributes:{
          name:                this.get('name'),
          legal_name:          this.get('legal_name'),
          taxid:               this.get('taxid'),
          registration:        this.get('registration'),
          email:               this.get('email'),
          birth_date:          this.get('birth_date'),
          phone:               this.get('phone'),
          cell_phone:          this.get('cell_phone'),
          share_capital:       this.get('share_capital'),
          observation:         this.get('observation'),
          is_customer:         this.get('is_customer'),
          is_legal:            this.get('is_legal'),
          active:              this.get('active'),
          sub_cooperative_id:  this.get('sub_cooperative')?.get('id'),
          attachments_ids:     ArchiveRecord.getNewFiles( this.get('attachments') ),
          del_attachments_ids: ArchiveRecord.getDeleteFiles( this.get('attachments') ),
          user_attributes:     {},
          address_attributes:  this.get('address')?.nestedParams(),
          ...extra
        }
      }
    };
  }
}
