/* eslint prefer-const: "off" */
import PropTypes from 'prop-types';
import { NOTIFICATION_ADD }from '~/store/modules/notifications';
import {
  CRUD_FORM_SUBMIT_TOGGLE,
  CRUD_DIALOG_FORM_CHANGE
}from '~/store/modules/crud';
import { call, put, select } from 'redux-saga/effects'
import deserialize from '@src/utils/deserialize';
import history from '@src/services/history';
import formatResponseError from '@src/utils/formatResponseError';
import Request from '@src/services/request';

const propTypes = {
  reducerKey: PropTypes.string.isRequired,

  routes: PropTypes.shape({
    list:  PropTypes.func,
    show:  PropTypes.func,
    new:   PropTypes.func,
  }).isRequired,

  apiRequests: PropTypes.shape({
    fetchListApi:     PropTypes.func,
    findRecordApi:    PropTypes.func,
    createRecordApi:  PropTypes.func,
    updateRecordApi:  PropTypes.func,
    destroyRecordApi: PropTypes.func,
  }).isRequired,

  actionTypes: PropTypes.shape({
    FETCH_START:      PropTypes.string,
    FETCH_DONE:       PropTypes.string,
    FETCH_LIST_START: PropTypes.string,
    FETCH_LIST_DONE:  PropTypes.string,
    CHANGE_LIST:      PropTypes.string,
    CHANGE_CURRENT:   PropTypes.string,
    SET_ERRORS:       PropTypes.string,
  }).isRequired
};

export default class CrudActions {
  constructor(props) {
    PropTypes.checkPropTypes(propTypes, props, 'params', 'CrudActions');
    this.props = props;
    this.fetchListAction = this.fetchListAction.bind(this)
    this.createAction = this.createAction.bind(this)
    this.updateAction = this.updateAction.bind(this)
    this.destroyAction = this.destroyAction.bind(this)
    this.showAction = this.showAction.bind(this)
    this.getStore = this.getStore.bind(this)
    this.setError = this.setError.bind(this)
  }

  getStore(store){ return store[this.props.reducerKey]  }
  getCrudStore(store){ return store.crud  }

  *setError(err, defaultMessage) {
    const { SET_ERRORS } = this.props.actionTypes;

    if (err.status === Request.HTTP_STATUS.UNPROCESSABLE_ENTITY) {
      const errors = formatResponseError(err.errors);
      yield put({
        type: SET_ERRORS,
        payload: {
          errors
        }
      });
      yield put({
        type: NOTIFICATION_ADD,
        payload: {
          title: defaultMessage,
          type: 'error',
        }
      });
    } else {
      yield put({
        type: NOTIFICATION_ADD,
        payload: {
          title: defaultMessage,
          message: err.message,
          type: 'error',
        }
      });
    }
  }

  *fetchListAction ({payload}) {
    const { FETCH_LIST_START, FETCH_LIST_DONE, CHANGE_LIST } = this.props.actionTypes;
    const { fetchListApi } = this.props.apiRequests;
    const { params, requestSource, callbackSuccess, callbackFailed } = payload;

    yield put({type: FETCH_LIST_START});

    try {
      const response = yield call(fetchListApi, params, requestSource);
      let current = response.data;

      if( `${params}`.includes('adapter=json') ){
        current = response.data?.data || []
      }else if( current.data ){
        current = yield call(deserialize, current);
      }

      yield put({
        type: CHANGE_LIST,
        payload: {
          data:  current,
          totalData: response.data?.meta?.total,
        }
      });

      if(typeof callbackSuccess === 'function'){
        callbackSuccess(current, response, params)
      }
    } catch(error) {
      console.error(error);
      if(typeof callbackFailed === 'function'){
        callbackFailed(error)
      }else{
        if(error.message !== 'CRUD_FETCH_CANCELED'){
          yield this.setError( error, 'Não foi possível carregar os dados.' );
        }
      }
    }

    yield put({type: FETCH_LIST_DONE});
  }

  *createAction ({payload}) {
    const { routes } = this.props;
    const { CHANGE_CURRENT, FETCH_DONE } = this.props.actionTypes;
    const { createRecordApi } = this.props.apiRequests;
    const { data, params, callbackSuccess, callbackFailed } = payload;

    yield put({
      type: CRUD_FORM_SUBMIT_TOGGLE,
      payload: { isSaving: true }
    });

    try {
      const response = yield call(createRecordApi, data, params);
      let current = response.data;
      if(response.data?.data?.attributes){
        current = yield call(deserialize, response.data);
      }
      yield put({
        type: CHANGE_CURRENT,
        payload: { current }
      });

      yield put({
        type: NOTIFICATION_ADD,
        payload: {
          title: 'Registro criado com sucesso!',
          action: {
            label: 'VER',
            href: routes.show(current.id, params)
          }
        }
      });


      if(typeof callbackSuccess === 'function'){
        callbackSuccess(current, response)
      }else{
        const crudStore = yield select(this.getCrudStore)
        if(crudStore?.form?.saveAndContinue){
          const newRoute = routes.new(current.id, params);
          history.push(
            newRoute.includes('?') ? `${newRoute}&savedID=${current.id}` : `${newRoute}?savedID=${current.id}`
          );
        }else{
          yield put({
            type: CRUD_DIALOG_FORM_CHANGE,
            payload: {
              dialogFormIsOpen: false
            }
          });
          history.push( routes.show(current.id, params) );
        }
      }
    } catch(error) {
      console.error(error);
      if(typeof callbackFailed === 'function'){
        callbackFailed(error)
      }else{
        yield this.setError( error, 'Não foi possível criar o registro.' );
      }
    }

    yield put({type: FETCH_DONE})
    yield put({
      type: CRUD_FORM_SUBMIT_TOGGLE,
      payload: { isSaving: false }
    });
  }

  *updateAction ({payload}) {
    const { routes } = this.props;
    const { CHANGE_CURRENT, FETCH_DONE } = this.props.actionTypes;
    const { updateRecordApi } = this.props.apiRequests;
    const { id, data, params, callbackSuccess, callbackFailed } = payload;

    yield put({
      type: CRUD_FORM_SUBMIT_TOGGLE,
      payload: { isSaving: true }
    });

    try {
      const response = yield call(updateRecordApi, id, data, params);
      let current = response.data;
      if(response.data?.data?.attributes){
        current = yield call(deserialize, response.data);
      }
      yield put({
        type: CHANGE_CURRENT,
        payload: { current }
      });

      yield put({
        type: NOTIFICATION_ADD,
        payload: {
          title: 'Registro atualizado com sucesso!',
        }
      });


      if(typeof callbackSuccess === 'function'){
        callbackSuccess(current, response)
      }else{
        yield put({
          type: CRUD_DIALOG_FORM_CHANGE,
          payload: {
            dialogFormIsOpen: false
          }
        });
        history.push( routes.show(current.id, params) );
      }
    } catch(error) {
      console.error(error);
      if(typeof callbackFailed === 'function'){
        callbackFailed(error)
      }else{
        yield this.setError( error, 'Não foi possível atualizar o registro.' );
      }
    }

    yield put({type: FETCH_DONE})

    yield put({
      type: CRUD_FORM_SUBMIT_TOGGLE,
      payload: { isSaving: false }
    });
  }

  *destroyAction ({payload}) {
    const { actionTypes, apiRequests, routes } = this.props;
    const { CHANGE_LIST } = actionTypes;
    const { destroyRecordApi } = apiRequests;
    const { id, params, callbackSuccess } = payload;

    try {
      yield call(destroyRecordApi, id, params)
      const currentStore = yield select(this.getStore)
      let newData = [...currentStore.data];
      const idx = newData.map(e => e.id).indexOf(id);
      newData.splice(idx, 1);

      yield put({
        type: CHANGE_LIST,
        payload: {
          data:  newData
        }
      });

      yield put({
        type: NOTIFICATION_ADD,
        payload: {
          title: 'Registro excluído com sucesso!',
        }
      });

      if(typeof callbackSuccess === 'function'){
        callbackSuccess()
      }else{
        history.push( routes.list(params) );
      }

    } catch(error) {
      console.error(error);
      yield put( handleFailResponse(error, 'Não foi possível remover o registro.') );
    }
  }

  *showAction ({payload}) {
    const { actionTypes, apiRequests } = this.props;
    const { CHANGE_CURRENT, FETCH_START, FETCH_DONE } = actionTypes;
    const { findRecordApi } = apiRequests;
    const { id, params, callbackSuccess, callbackFailed } = payload;

    const currentStore = yield select(this.getStore)
    const cacheData = currentStore.data.find( e => String(e.id) === String(id) )

    yield put({
      type: FETCH_START,
      payload: {current: cacheData},
    });

    try {
      const response = yield call(findRecordApi, id, params);
      let current = response.data;
      if(response.data?.data?.attributes){
        current = yield call(deserialize, response.data);
      }

      if(typeof callbackSuccess === 'function'){
        callbackSuccess(current, response)
      }else{
        yield put({
          type: CHANGE_CURRENT,
          payload: { current }
        });
      }

    } catch(err) {
      console.error(err);
      if(typeof callbackFailed === 'function'){
        callbackFailed(err)
      }else{
        yield put({
          type: NOTIFICATION_ADD,
          payload: {
            title: 'Não foi possível carregar o registro!',
            list: err.errors
          }
        })
      }
    }

    yield put({type: FETCH_DONE});
  }
}

function handleFailResponse(error, title='Não foi possível realizar a ação!'){
  const { response } = error;
  try {
    if( response.status === 400 ){
      return ({
        type: NOTIFICATION_ADD,
        payload: {
          title: title,
          list: response.data.map( e => e.message),
          color: 'danger'
        }
      });
    }else{
      return ({
        type: NOTIFICATION_ADD,
        payload: {
          title: 'Oops, houve um erro na resposta do servidor!',
          list: [`${response.status} - ${response.statusText}!`],
          color: 'warning'
        }
      });
    }
  } catch(e) {
    return ({
      type: NOTIFICATION_ADD,
      payload: {
        title: 'Oops, ocorreu um erro não identificado!',
        message: 'Entre em contato com o administrador do sistema.',
        list: [e.toString()],
        color: 'warning'
      }
    });
  }
}