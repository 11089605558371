import SchemaBuilder from './SchemaBuilder'

const columns = {
  id:            { type: 'integer',  width: 80,  default: null, title: 'ID' },
  name:          { type: 'string',   width: 350, default: '',   title: 'Nome' },
  updated_at:    { type: 'datetime', width: 200, title: 'Última atualização' },
  created_at:    { type: 'datetime', width: 200, title: 'Criado em' },
}

const genericSchema = new SchemaBuilder( columns )

export default genericSchema
