import createReducer from '~/store/utils/createReducer';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import Request from '@src/services/request';
import history from '@src/services/history';
import { authRoute } from '@src/routes'
import { authApi } from '@src/routes/api';

/* =================================================
  REDUX TYPES
================================================= */
export const FORGOT_PASSWORD_REQUEST = '@App/auth/FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = '@App/auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = '@App/auth/FORGOT_PASSWORD_FAILURE';

/* =================================================
  REDUX REDUCER
================================================= */
const INITIAL_STATE = {
  loading: false
};

export default createReducer(
  INITIAL_STATE,
  {
    [FORGOT_PASSWORD_REQUEST]: (draft) => { draft.loading = true; },
    [FORGOT_PASSWORD_SUCCESS]: (draft) => { draft.loading = false; },
    [FORGOT_PASSWORD_FAILURE]: (draft) => { draft.loading = false; }
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function forgotPasswordRequest(email) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: { email }
  }
}

export function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_SUCCESS
  }
}

export function forgotPasswordFailure() {
  return {
    type: FORGOT_PASSWORD_FAILURE
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
function* forgotPassword({ payload }) {
  const { email } = payload;

  try {
    const response = yield call(
      Request.post,
      authApi.usersPassword.build(),
      { email }
    );

    if (response.status === Request.HTTP_STATUS.NO_CONTENT) {
      yield put(forgotPasswordSuccess());
      toast.success('Em instantes voçê receberá um e-mail com instruções de redefinição de senha');
      history.replace(authRoute.signIn.path, {});
    }
  } catch(err) {
    yield put(forgotPasswordFailure(err));
    toast.error('Aconteceu um problema ao recuperar sua senha. Tente novamente mais tarde!');
  }
}

export const saga = all([
  takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword)
]);