import React, { useEffect } from 'react';

import queryStringParser from '@src/utils/queryStringParser';
import { authApi } from '@src/routes/api'
import { authRoute } from '@src/routes'

import FullScreenLoading from '~/components/FullScreenLoading';
import {
  Container,
  BackgroundColorAbsolute,
  ContainerLogin,
  ContentLogin,
  Logo,
} from './styles';

const authUrl = process.env.REACT_APP_AUTH_API_URL;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;

const AuthRedirect = () => {
  useEffect(() => {
    const paramsUrl = {
      redirect_uri: `${window.location.origin}${authRoute.callback.build()}`,
      response_type: 'code',
      client_id: clientId
    }

    const loginURL = `${authUrl}${authApi.autorize.build()}?${queryStringParser(paramsUrl)}`

    window.location.href = loginURL
  }, [])

  return (
    <Container>
      <BackgroundColorAbsolute />
      <ContainerLogin>
        <ContentLogin>
          <Logo />
          <FullScreenLoading loadingText="Carregando..." />
        </ContentLogin>
      </ContainerLogin>
    </Container>
  );
}

export default AuthRedirect;
