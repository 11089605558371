import React from "react";

import { customRecordsRoute } from '@src/routes'

import ListItemLink from '../ListItemLink';

import Icon from '@material-ui/core/Icon';

export default function CustomRecordLink({isSelected, metaData}){
  const path = customRecordsRoute.list.build({ sys_key: metaData.sys_key });

  // eslint-disable-next-line
  const CustomIcon = ({htmlColor, ...props}) => {
    return <Icon {...props}>{metaData.icon_lib_record || 'dynamic_form'}</Icon>
  }
  return (
    <ul style={{ padding: '0' }}>
      <ListItemLink
        selected={ isSelected(path) }
        name={metaData.record_plural_name}
        to={path}
        isCollapsible={false}
        collapseIsOpen={false}
        icon={CustomIcon}
      />
    </ul>
  )
}