import React from "react"
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import themeStyle from '~/theme/theme'

const customTheme = createTheme(themeStyle)

export default function Theme({ children }) {
  return (
    <ThemeProvider theme={customTheme}>
      {children}
    </ThemeProvider>
  )
}