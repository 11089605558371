import styled from 'styled-components';
import smallLogo from '@src/assets/images/small-logo.png';
import fullLogo from '@src/assets/images/full-logo.png';

export const Container = styled.div`
  display: flex;
  position: ${props => props.drawerOpen ? 'relative' : 'absolute'};
`;

export const Logo = styled.div`
  width: 100%;
  height: 100%;
  animation-delay: 0;
  transition: background-image ${props => props.drawerOpen ? '250ms' : '150ms'} ease-in-out;
  background-image: url(${props => props.drawerOpen ? fullLogo : smallLogo});
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 50%;
  background-size: contain;
  background-origin: content-box;
  padding: 5px;
`;

export const ProfileContainer = styled.div.attrs({
  style: {
    height: 50,
  }
})`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  a {
    display: flex;
    flex: 1;
    height: 100%;
    max-width: 210px;
    padding-left: 10px;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 15px 0 10px;
  flex-direction: column;

  strong {
    color: ${props => props.color};
    font-size: 1rem;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end;
  }

  p {
    color: ${props => props.color};
    font-size: 0.8rem;
    margin: 0;
  } 
`;
