import React, { createContext, useEffect, useState, useContext } from 'react';
import { Map as ImmutableMap  } from 'immutable';
import Request from '@src/services/request';
import MetadatumRecord from '@src/records/MetadatumRecord';
import AnimatedLoadingFull from '~/components/AnimatedLoadingFull';

const MetadataContext = createContext({
  metadata: ImmutableMap([]),
});

export const MetadataProvider = ({ children }) => {
  const [metadata, setMetadata] = useState(ImmutableMap([]));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMetadata = async () => {
      try{
        const response = await Request.get('/api/v1/metadata_settings')
        const result = response.data.data;

        setMetadata(
          ImmutableMap(
            result.map( meta => [meta.sys_key, new MetadatumRecord(meta)] )
          )
        )
      } catch(error){ console.error(error) }

      setTimeout(() => { setIsLoading(false); }, 150);
    }
    fetchMetadata()
  }, [])

  return (
    <MetadataContext.Provider value={{ metadata }}>
      { !isLoading && children }
      { isLoading && <AnimatedLoadingFull /> }
    </MetadataContext.Provider>
  );
};

export const useMetadata = () => {
  const context = useContext(MetadataContext);

  if (!context) {
    throw new Error('useMetadata must be used within a MetadataProvider');
  }

  return context;
};

export default MetadataContext;
