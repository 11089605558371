import React, { useState, useEffect, useCallback } from 'react';
import BaseLayout from '~/components/BaseLayout';

import { dashboardsApi } from '@src/routes/api'
import Request from '@src/services/request'

import Skeleton from '@material-ui/lab/Skeleton';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import Totalizers from './components/Totalizers';

import PaperChart from './components/PaperChart';
import PieChart from './components/PieChart';
import DoughnutChart from './components/DoughnutChart';

import InputControl from '~/components/Input/InputControl';
import InputSelectAsync from '~/components/Input/InputSelectAsync';
import { cooperativesFetch } from '@src/services/selectFetch/cooperativesFetch'

import { useSelector } from 'react-redux';

export default function Dashboard() {
  const userProfile = useSelector(state => state.userProfile.data || {});
  const [cooperative, setCooperative] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [stats, setStats] = useState({})

  useEffect(() => {
    Request.get(`${dashboardsApi.stats.build()}?cooperative=${cooperative?.id}`).then( (resp) => {
      setStats(resp.data)
      setIsLoading(false)
    })
  },[cooperative])

  const onSelectCoop = useCallback(
    ({coop}) => {
      setIsLoading(true)
      setCooperative(coop)
    },
    [setCooperative]
  )

  return (
    <BaseLayout>
      { ['master','admin','user'].includes(userProfile.access_type) &&
        <GridContainer className="hidden-print">
          <Col xs={12} >
            <InputControl
              value={cooperative}
              name={'coop'}
              label={'Cooperativa'}
              inputComponent={InputSelectAsync}
              onChange={onSelectCoop}
              InputProps={{
                handleFetch: cooperativesFetch,
                placeholder: 'Todas as cooperativas',
                isClearable: true,
              }}
            />
          </Col>
        </GridContainer>
      }
      <GridContainer>
        <Col xs={12}>
          <Totalizers cooperative={cooperative}/>
        </Col>
      </GridContainer>

      { isLoading &&
        <GridContainer>
        <Col xs={12}>
          <Skeleton />
        </Col>
        </GridContainer>
      }
      { !isLoading &&
        <GridContainer>
          <Col xs={12} sm={6} md={4}>
            <PaperChart
              title="Protecional"
              data={stats.fire_protection_type}
              chartID={'fire_protection_type'}
              dataLabels={['Nenhum', 'Extintor', 'Hidrante', 'Ambos']}
              dataKeys={['none', 'extinguisher', 'hydrants', 'extinguisher_and_hydrants']}
              colors={['rgba(255, 99, 132)', 'rgba(54, 162, 235)', 'rgba(255, 206, 86)', 'rgba(75, 192, 192)']}
              Chart={DoughnutChart}
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <PaperChart
              title="Tipo de Parede"
              data={stats.wall_type}
              chartID={'wall_type'}
              dataLabels={['Alvenaria', 'Combustível']}
              dataKeys={['masonry', 'combustible']}
              colors={['#2962ff', '#ef6c00']}
              Chart={DoughnutChart}
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <PaperChart
              title="Aquecimento (Forno)"
              data={stats.heating_type}
              chartID={'heating_type'}
              dataLabels={['Nenhum', 'Interno', 'Sala Isolada']}
              dataKeys={['none', 'oven_inside', 'oven_outside']}
              colors={['rgba(255, 99, 132)', 'rgba(54, 162, 235)', 'rgba(255, 206, 86)', 'rgba(75, 192, 192)']}
              Chart={DoughnutChart}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <PaperChart
              title="Travejamento"
              data={stats.beam_type}
              chartID={'beam_type'}
              dataLabels={['Madeira', 'Concreto/Metálico']}
              dataKeys={['wood', 'concrete']}
              colors={['rgba(153, 102, 255)', 'rgba(255, 159, 64)']}
              Chart={PieChart}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <PaperChart
              title="Idade caixas d'àgua"
              data={stats.water_tank_time}
              chartID={'water_tank_time'}
              dataLabels={['< 1', '1 ~ 5', '6 ~ 10', '11 ~ 20', '> 20']}
              dataKeys={[
                'less_than_one_year',
                'from_one_to_five_years',
                'from_six_to_ten_years',
                'from_eleven_to_twenty_years',
                'more_than_twenty_years'
              ]}
              colors={[
                'rgba(255, 99, 132)',
                'rgba(54, 162, 235)',
                'rgba(255, 206, 86)',
                'rgba(75, 192, 192)',
                'rgba(153, 102, 255)',
                'rgba(255, 159, 64)'
              ]}
              Chart={PieChart}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <PaperChart
              title="Idade dos Silos"
              data={stats.silo_time}
              chartID={'silo_time'}
              dataLabels={['< 1', '1 ~ 5', '6 ~ 10', '11 ~ 20', '> 20']}
              dataKeys={[
                'less_than_one_year',
                'from_one_to_five_years',
                'from_six_to_ten_years',
                'from_eleven_to_twenty_years',
                'more_than_twenty_years'
              ]}
              colors={[
                'rgba(255, 99, 132)',
                'rgba(54, 162, 235)',
                'rgba(255, 206, 86)',
                'rgba(75, 192, 192)',
                'rgba(153, 102, 255)',
                'rgba(255, 159, 64)'
              ]}
              Chart={PieChart}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <PaperChart
              title="Tipos de colunas"
              data={stats.column_type}
              chartID={'column_type'}
              dataLabels={['Madeira', 'Alvenaria', 'Mista']}
              dataKeys={['wooden', 'masonry', 'mixed']}
              colors={[
                'rgba(255, 99, 132)',
                'rgba(54, 162, 235)',
                'rgba(75, 192, 192)'
              ]}
              Chart={PieChart}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3}>
            <PaperChart
              title="Tipos de telhas"
              data={stats.tiles_type}
              chartID={'tiles_type'}
              dataLabels={['Cerâmica/Metálica/Fibrocimento', 'Isotérmicas', 'Outro']}
              dataKeys={['ceramic_metallic_fiber', 'isothermal', 'other']}
              colors={['#2962ff', '#e65100', '#5d4037']}
              Chart={PieChart}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <PaperChart
              title="Caixa de Disjuntores"
              data={stats.breaker_box_type}
              chartID={'breaker_box_type'}
              dataLabels={['Madeira aparente', 'Metálica embutida', 'Metálica aparente', 'Outro']}
              dataKeys={['builtin_wood_circuit', 'builtin_metal_circuit', 'apparent_metallic_circuit', 'other']}
              colors={['#2962ff','#ef6c00','#558b2f','#616161']}
              Chart={PieChart}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <PaperChart
              title="Tipo de forro"
              data={stats.lining_type}
              chartID={'lining_type'}
              dataLabels={['Combustível', 'Outro']}
              dataKeys={['combustible', 'other']}
              colors={['#2962ff','#ef6c00','#558b2f','#616161']}
              Chart={PieChart}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <PaperChart
              title="Tipos de Ninho"
              data={stats.nest_type}
              chartID={'nest_type'}
              dataLabels={['Automático', 'Madeira', 'Nenhum']}
              dataKeys={['automatic', 'wook', 'none']}
              colors={['#2962ff','#ef6c00','#558b2f','#616161']}
              Chart={PieChart}
            />
          </Col>
        </GridContainer>
      }
    </BaseLayout>
  );
}
