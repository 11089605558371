import Route from '@src/services/route'

export const singularName = 'Técnico'
export const pluralName = 'Técnicos'
export const permissionKeyCode = 'Technician'

export const techniciansApi = {
  show:    new Route('/api/v1/technicians/:id'),
  update:  new Route('/api/v1/technicians/:id'),
  destroy: new Route('/api/v1/technicians/:id'),
  create:  new Route('/api/v1/technicians'),
  list:    new Route('/api/v1/technicians')
}

export const techniciansRoute = {
  list:  new Route('/sys/tecnicos'),
  show:  new Route('/sys/tecnicos/:id'),
  clone: new Route('/sys/tecnicos/new/:id'),
  new:   new Route('/sys/tecnicos/new'),
  edit:  new Route('/sys/tecnicos/:id/edit')
}

export const defaultFindOneIncludes = 'user-sub_cooperative-cooperative-address.city'