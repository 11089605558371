import React from 'react';

import { usePermissions } from '~/hooks/usePermissions';

function Permission({ keyCode, permission, children, onlyMaster }) {
  const { can } = usePermissions();
  const hasPermission = can(keyCode, permission, onlyMaster);

  if (!hasPermission) return <></>;

  return children;
}

Permission.defaultProps = {
  onlyMaster: false
}

export default Permission;
