import React from 'react';
import Grid  from '@material-ui/core/Grid';

export default function Col({children, xs, ...props }) {
  const colXS = xs || 12
  return (
    <Grid item xs={colXS} {...props} >
      {children}
    </Grid>
  );
}
