import React from 'react';
import { Switch } from 'react-router-dom';

import { dashboardRoute } from '@src/routes'
import RouteWrapper from '@src/routes/RouteWrapper';
import Dashboard from './Dashboard';

export default function DashboardRoute() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={dashboardRoute.self.path}
        component={Dashboard}
        permissionKeyCode="Dashboard"
        permission="see"
      />
    </Switch>
  );
}