
export const PALETTE_COLORS = [
  'inherit',
  'primary',
  'secondary',
  'error',
  'warning',
  'success',
  'info',
  'blue',
  'purple',
  'green',
  'orange',
  'red',
  'black',
  'grey',
  'rose',
  'white'
]

export const TYPOGRAPHY_PALETTE_COLORS = [
  'initial',
  'inherit',
  'textPrimary',
  'textSecondary'
]