import React, { useMemo } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import MuiListItem from '@material-ui/core/ListItem';
import makeTheme from '~/theme/makePrimaryColorTheme'

const ListItem = ({color, ...props}) => {
  const theme = useMemo(() => makeTheme(color));
  return <ThemeProvider theme={theme}>
    <MuiListItem {...props} color='primary'/>
  </ThemeProvider>
}

export default React.memo(ListItem)