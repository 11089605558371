import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { authRoute } from '@src/routes'
import { httpStatusRoute } from '@src/routes'

import SignIn from '~/pages/SignIn';
import SignOut from '~/pages/SignOut';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';
import NotFoundComponent from '~/pages/NotFound/NotFound';
import AuthRedirect from '~/pages/Auth'

export const DefaultOriginRouteComponent = () => (
  <Redirect
    to={authRoute.signIn.path}
  />
);

export default function PublicRoutes() {
  return (
    <Switch>
      <Route path={authRoute.signIn.path} component={AuthRedirect} exact />
      <Route path={authRoute.callback.path} component={SignIn} exact />
      <Route path={authRoute.signOut.path} component={SignOut} exact />
      <Route path={authRoute.forgotPassword.path} component={ForgotPassword} exact />
      <Route path={authRoute.resetPassword.path} component={ResetPassword} exact />
      <Route path={httpStatusRoute.notFound.path} component={NotFoundComponent} exact />
      <Route component={AuthRedirect} />
    </Switch>
  );
}
