const formatUserAccessType = (value) => {
  const access_object = {
    master:      'Mestre',
    admin:       'Administrador',
    user:        'Usuário',
    customer:    'Cliente',
    broker:      'Seguradora',
    techinician: 'Técnico',
    producer:    'Produtor',
    techinician_manager: 'Adm. Técnico'
  }

  return access_object[value] || 'Cliente';
}

export default formatUserAccessType
