import Route from '@src/services/route'

export const singularName = 'Gerente Técnico'
export const pluralName = 'Gerentes Técnicos'
export const permissionKeyCode = 'TechinicianManager'

export const techinicianManagersApi = {
  show:    new Route('/api/v1/techinician_managers/:id'),
  update:  new Route('/api/v1/techinician_managers/:id'),
  destroy: new Route('/api/v1/techinician_managers/:id'),
  create:  new Route('/api/v1/techinician_managers'),
  list:    new Route('/api/v1/techinician_managers')
}

export const techinicianManagersRoute = {
  list:  new Route('/sys/gerentes_tecnicos'),
  show:  new Route('/sys/gerentes_tecnicos/:id'),
  clone: new Route('/sys/gerentes_tecnicos/new/:id'),
  new:   new Route('/sys/gerentes_tecnicos/new'),
  edit:  new Route('/sys/gerentes_tecnicos/:id/edit')
}

export const defaultFindOneIncludes = 'user-sub_cooperative-cooperative-address.city'