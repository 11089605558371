import Route from '@src/services/route'

export const singularName = 'Sub-Cooperativa'
export const pluralName = 'Sub-Cooperativas'
export const permissionKeyCode = 'SubCooperative'

export const subCooperativesApi = {
  show:    new Route('/api/v1/admin/sub_cooperatives/:id'),
  update:  new Route('/api/v1/admin/sub_cooperatives/:id'),
  destroy: new Route('/api/v1/admin/sub_cooperatives/:id'),
  create:  new Route('/api/v1/admin/sub_cooperatives'),
  list:    new Route('/api/v1/admin/sub_cooperatives')
}

export const subCooperativesRoute = {
  list:  new Route('/sys/sub_cooperativas'),
  show:  new Route('/sys/sub_cooperativas/:id'),
  clone: new Route('/sys/sub_cooperativas/new/:id'),
  new:   new Route('/sys/sub_cooperativas/new'),
  edit:  new Route('/sys/sub_cooperativas/:id/edit')
}

export const defaultFindOneIncludes = 'cooperative'