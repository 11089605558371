import axios from 'axios';
import { toast } from 'react-toastify';
import { refreshToken } from '~/store/modules/auth';
import { store } from '~/store';
import { authRoute } from '@src/routes';
import { authApi } from '@src/routes/api';
import Request from './request';

const BASE_URL = process.env.REACT_APP_AUTH_API_URL;
const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'responseType': 'json'
  },
});

class Auth {
  static _mountRedirectUrl() {
    return `${window.location.origin}${authRoute.callback.build()}`
  }

  static _mountLoginParameters(parameters) {
    return {
      client_id: CLIENT_ID,
      grant_type: 'authorization_code',
      redirect_uri: Auth._mountRedirectUrl(),
      ...parameters
    }
  }

  static _setAuthorizationHeader(code) {
    return {
      headers: {
        'Authorization': `Bearer ${code}`
      }
    }
  }

  static async signIn(params) {
    return await api.post(
      authApi.token.build(),
      Auth._mountLoginParameters(params),
      Auth._setAuthorizationHeader(params.code)
    )
  }

  static async revokeRefreshToken() {
    const { auth } = store.getState();

    return await api.post(
      authApi.revoke.build(),
      {
        client_id: CLIENT_ID,
        token: auth.refreshToken
      },
      Auth._setAuthorizationHeader(auth.token)
    )
  }

  static async fetchRefreshToken() {
    try {
      const { auth } = store.getState();
      const response = await api.post(authApi.token.path, {
        grant_type: 'refresh_token',
        client_id: CLIENT_ID,
        refresh_token: auth.refreshToken
      });

      if (!response.data) {
        Auth.alertError();
        return false;
      }

      store.dispatch(refreshToken({
        token: response.data.access_token,
        refreshToken: response.data.refresh_token
      }));

      Request.setHeader(
        'Authorization',
        `Bearer ${response.data.access_token}`
      );

      return response.data.access_token;
    } catch (e) {

      Auth.alertError();
      return false;
    }
  }

  static alertError() {
    setTimeout(() => {
      window.location = `${window.location.origin}${authRoute.signIn.build()}`
    }, 1000)
    toast.error('ATENÇÃO! Sua sessão encerrou. Faça Login para continuar navegando.');
  }
}

export default Auth;
