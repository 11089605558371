import jwt_decode from 'jwt-decode';
import { store } from '~/store';

const getMe = () => {
  const { token } = store.getState().auth;
  try {
    const me = jwt_decode(token).user;
    if(me)
      return me;
  } catch(error) {
    console.error({error})
    return false
  }
}

export default getMe;