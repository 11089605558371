import Request from '@src/services/request'
import queryStringParser from '@src/utils/queryStringParser'

import { subCooperativesApi } from './subCooperatives.config'

export const findRecordRequest = (id, args={}) => {
  return Request.get( `${ subCooperativesApi.show.build({id}) }?${queryStringParser(args)}` );
};

export const fetchListRecordsRequest = (args={}, requestSource) => {
  return Request.get( `${ subCooperativesApi.list.build() }?${queryStringParser(args)}`, {
    cancelToken: requestSource?.token
  } );
};

export const destroyRecordRequest = (id, args={}) => {
  return Request.del( subCooperativesApi.destroy.build({id}), args );
};

export const updateRecordRequest = (id, data={}, args={} ) => {
  return Request.put( `${ subCooperativesApi.update.build({id}) }?${queryStringParser(args)}`, data );
};

export const createRecordRequest = (data={}, args={}) => {
  return Request.post( `${ subCooperativesApi.create.build() }?${queryStringParser(args)}`, data );
};

export const subCooperativesFetch = (inputValue, page, doneCallback) => {
  return Request.get(`/api/v1/search/sub_cooperatives?qs=${inputValue}&page=${page}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data.map( e => ({
          id: e.id,
          name: e.name,
        }))
      }
    )
  })
  .catch(err => err);
}

export const subCooperativesShow =  (id) => {
  return Request.get(`/api/v1/search/sub_cooperatives/${id}`).then(resp => {
    if(resp.data && resp.data.data){
      return resp.data.data
    }
    return { id: null, name: 'Não localizado' };
  })
  .catch(err => err);
}