import styled from 'styled-components';
import { rgba } from 'polished';

import MUIButton from '~/components/Button';
import theme from '~/theme/theme';

import backgroundImg from '@src/assets/images/background-image.png';
import logoImg from '@src/assets/images/full-logo-white.svg';

export const Container = styled.section`
  height: 100vh;
  width: 100%;
  background: url(${backgroundImg}) no-repeat center;
  background-size: cover;
`;

export const BackgroundColorAbsolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: ${rgba(theme.palette.purple.main, 0.65)};
  z-index: 1;
`;

export const ContainerLogin = styled.div`
  display: flex;
  position: absolute;
  top: 15vh;
  left: calc((100% - 740px) / 2);
  height: 70vh;
  width: 100%;
  max-width: 740px;
  background-color: ${theme.palette.black.main};
  z-index: 10;
  place-content: center;
  align-items:center;
  border-radius: 20px;
  box-shadow: 0px 4px 20px ${rgba(theme.palette.black.dark, 0.3)};

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;

    & label {
      color: ${theme.palette.white.main};
    }

    & input {
      color: ${theme.palette.white.main};
    }

    /* Muda a cor da label do input */
    & label.Mui-focused {
      color: white;
    }

    /* Muda a cor da label do input quando o mesmo estiver com focus */
    & .MuiInput-underline:before {
      border-bottom-color: white;
    }

    /* Muda a cor da borda do input no hover */
    & .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom-color: white;
    }

    /* Muda a cor da borda do input quando o mesmo estiver com focus */
    & .MuiInput-underline::after {
      border-bottom-color: white;
    }
  }
`;

export const Logo = styled.img.attrs({
  src: logoImg
})`
  max-width: 300px;
  margin-bottom: 50px;
`;

export const Button = styled(MUIButton)`
  width: 100%;
  margin-top: 150px;
`;
