import React, {useMemo, forwardRef, useCallback} from 'react';
import { Link } from 'react-router-dom';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronDown from '@material-ui/icons/KeyboardArrowDown';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import cx        from 'classnames';

import ListItem from '~/components/ListItem';

import { layoutMenuBgColor } from '~/theme/systemPalette'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( () => ({
  listIconRoot: {
    color: '#ffcc00'
  },
  listItemCollapsed: {
    paddingLeft: '50px'
  },
  listItemDefault: {
    paddingLeft: '25px'
  }
}) )

export default function ListItemLink({
  icon: ItemIcon,
  name,
  selected,
  isCollapsible,
  to,
  isCollapsedItem,
  collapseIsOpen,
  onClick
}) {
  const classes = useStyles()

  const renderLink = useMemo(
    () =>
      forwardRef( (linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to],
  );

  const colapseProps = useMemo( () => (
    isCollapsible ? {} : { component: renderLink }
  ), [isCollapsible, renderLink])

  const itemClick = useCallback(
    () => {
      if(!isCollapsible) return
      onClick && onClick(name)
    },
    [onClick, name, isCollapsible]
  )

  return (
    <li>
      <ListItem
        color={layoutMenuBgColor}
        button
        selected={selected}
        onClick={itemClick}
        className={cx({
          [classes.listItemCollapsed]: isCollapsedItem,
          [classes.listItemDefault]:   !isCollapsedItem,
        })}
        {...colapseProps}
      >
        <ListItemIcon classes={{root: classes.listIconRoot}}>
          <ItemIcon/>
        </ListItemIcon>
        <ListItemText primary={name} />

        {isCollapsible && (
          collapseIsOpen
            ? <ChevronDown />
            : <ChevronRight />
        )}
      </ListItem>
    </li>
  );
}
