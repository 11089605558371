import React, { useMemo } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import MuiButton from '@material-ui/core/Button';
import makeTheme from '~/theme/makePrimaryColorTheme'
import PropTypes from 'prop-types';

import { PALETTE_COLORS } from '@src/config/constants'

const Button = ({color, ...props}) => {
  if(color === 'inherit'){
    return <MuiButton {...props} color='inherit'/>
  }
  const theme = useMemo(() => makeTheme(color));
  return <ThemeProvider theme={theme}>
    <MuiButton {...props} color='primary'/>
  </ThemeProvider>
}


Button.defaultProps = {
  ...MuiButton.defaultProps,
  color: 'primary',
  variant: 'contained',
};

Button.propTypes = {
  ...MuiButton.propTypes,
  color: PropTypes.oneOf(PALETTE_COLORS),
};

export default React.memo(Button)