import React, { useState, useEffect } from 'react'
import AnimatedNumber from "animated-number-react"

import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

import GridContainer from '~/components/GridContainer'
import Col from '~/components/Col'

import { Item } from './styles'

import { dashboardsApi } from '@src/routes/api'
import Request from '@src/services/request'

const RenderAnimatedNumber = ({value, ...props}) => {
  if(typeof value !== 'undefined'){
    return <AnimatedNumber
      value={value || 0}
      {...props}
    />
  }
  return (
    <Typography component="div" key={'body1'} variant={'body1'}>
      <Skeleton />
    </Typography>
  )
}

export default function Totalizers({cooperative}) {
  const [totals, setTotals] = useState({})
  useEffect(() => {
    Request.get(`${dashboardsApi.totalizers.build()}?cooperative=${cooperative?.id}`).then( (resp) => {
      setTotals(resp.data)
    })
  },[cooperative])

  return (
    <GridContainer>
      <Col xs={12} sm={6} lg={2}>
        <Item>
          <Typography variant="subtitle1">Qtd. Produtores</Typography>
          <h3><RenderAnimatedNumber value={totals.total_producers} duration={500} formatValue={value => parseInt(value)} /></h3>
        </Item>
      </Col>
      <Col xs={12} sm={6} lg={2}>
        <Item>
          <Typography variant="subtitle1">Vistorias Realizadas</Typography>
          <h3><RenderAnimatedNumber value={totals.total_done} duration={500} formatValue={value => parseInt(value)} /></h3>
        </Item>
      </Col>
      <Col xs={12} sm={6} md={3} lg={2}>
        <Item>
          <Typography variant="subtitle1">Vistorias Pendentes</Typography>
          <h3><RenderAnimatedNumber value={totals.total_pending} duration={500} formatValue={value => parseInt(value)} /></h3>
        </Item>
      </Col>
      <Col xs={12} sm={6} md={3} lg={2}>
        <Item>
          <Typography variant="subtitle1">Forro combustível</Typography>
          <h3>
            <RenderAnimatedNumber value={totals.total_lining_combustible} duration={700} formatValue={value => parseInt(value)} />
          </h3>
        </Item>
      </Col>
      <Col xs={12} sm={6} md={3} lg={2}>
        <Item>
          <Typography variant="subtitle1">Total de Silos</Typography>
          <h3><RenderAnimatedNumber value={totals.total_silos} duration={500} formatValue={value => parseInt(value)} /></h3>
        </Item>
      </Col>
      <Col xs={12} sm={6} md={3} lg={2}>
        <Item>
          <Typography variant="subtitle1">{"Total Caixas D'água"}</Typography>
          <h3>
            <RenderAnimatedNumber value={totals.total_water_tanks} duration={700} formatValue={value => parseInt(value)} />
          </h3>
        </Item>
      </Col>
    </GridContainer>
  )
}

