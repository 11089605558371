import React, { useRef, useCallback } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { getElementAtEvent } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Skeleton from '@material-ui/lab/Skeleton'

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

const options = {
 plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        let sum = 0
        let dataArr = ctx.chart.data.datasets[0].data
        dataArr.map(data => {
        sum += data
        })
        let percentage = (value*100 / sum).toFixed(2)

        if (percentage == 0.0) { return ''}

        return (`${value} (${percentage}%)`)
      },
      color: '#fff',
    }
  }
}

const buildData = (labels, data, colors) => ({
  labels,
  datasets: [
    {
      data,
      backgroundColor: colors,
      borderColor: colors,
      borderWidth: 1
    },
  ]
})

function openInNewTab(href) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: href,
  }).click()
}

export default function ChartHandler({ChartComponent, dataLabels, data, colors, chartID, dataKeys}) {
  const chartRef = useRef()
  const onClick = useCallback( (event) => {
    const clicked = getElementAtEvent(chartRef.current, event)
    openInNewTab(`/sys/propriedades?page=1&per_page=100&${chartID}=${dataKeys[clicked[0].index]}`)
  }, [data, chartRef, chartID, dataKeys])

  return (
    <>
      { !data && <Skeleton /> }
      { !!data &&
        <ChartComponent
          ref={chartRef}
          data={buildData(dataLabels, data, colors)}
          options={options}
          onClick={onClick}
          plugins={[ChartDataLabels]}
        />
      }
    </>
  )
}
