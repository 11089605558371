import { takeLatest, all } from 'redux-saga/effects';
import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';

import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from './insuranceBrokers.services';
import { insuranceBrokersRoute } from './insuranceBrokers.config'

/* =================================================
  REDUX TYPES
================================================= */
export const INSURANCE_BROKERS_SET_CURRENT = '@App/insuranceBrokers/INSURANCE_BROKERS_SET_CURRENT';
export const INSURANCE_BROKERS_FETCH_START = '@App/insuranceBrokers/INSURANCE_BROKERS_FETCH_START';
export const INSURANCE_BROKERS_FETCH_DONE  = '@App/insuranceBrokers/INSURANCE_BROKERS_FETCH_DONE';
export const INSURANCE_BROKERS_FETCH_LIST_START = '@App/insuranceBrokers/INSURANCE_BROKERS_FETCH_LIST_START';
export const INSURANCE_BROKERS_FETCH_LIST_DONE = '@App/insuranceBrokers/INSURANCE_BROKERS_FETCH_LIST_DONE';
export const INSURANCE_BROKERS_SET_ERRORS = '@App/insuranceBrokers/INSURANCE_BROKERS_SET_ERRORS';
export const CLEAR_STATE = '@App/insuranceBrokers/CLEAR_STATE';
export const FIND_INSURANCE_BROKERS_REQUEST = '@App/insuranceBrokers/FIND_INSURANCE_BROKERS_REQUEST';
export const DESTROY_INSURANCE_BROKERS_REQUEST = '@App/insuranceBrokers/DESTROY_INSURANCE_BROKERS_REQUEST';
export const UPDATE_INSURANCE_BROKERS_REQUEST = '@App/insuranceBrokers/UPDATE_INSURANCE_BROKERS_REQUEST';
export const CREATE_INSURANCE_BROKERS_REQUEST = '@App/insuranceBrokers/CREATE_INSURANCE_BROKERS_REQUEST';
export const FETCH_INSURANCE_BROKERS_REQUEST  = '@App/insuranceBrokers/FETCH_INSURANCE_BROKERS_REQUEST';
export const INSURANCE_BROKERS_CHANGE_LIST  = '@App/insuranceBrokers/INSURANCE_BROKERS_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [INSURANCE_BROKERS_FETCH_START]: fetchStart,
    [INSURANCE_BROKERS_FETCH_DONE]:  fetchDone,
    [INSURANCE_BROKERS_FETCH_LIST_START]: fetchListStart,
    [INSURANCE_BROKERS_FETCH_LIST_DONE]: fetchListDone,
    [INSURANCE_BROKERS_SET_ERRORS]: setErrors,
    [INSURANCE_BROKERS_SET_CURRENT]: setCurrent,
    [DESTROY_INSURANCE_BROKERS_REQUEST]: initLoading,
    [CREATE_INSURANCE_BROKERS_REQUEST]: initLoading,
    [UPDATE_INSURANCE_BROKERS_REQUEST]: initLoading,
    [INSURANCE_BROKERS_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}) {
  return {
    type: FIND_INSURANCE_BROKERS_REQUEST,
    payload: { id, params }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_INSURANCE_BROKERS_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_INSURANCE_BROKERS_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_INSURANCE_BROKERS_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_INSURANCE_BROKERS_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'insuranceBrokers',

  routes: {
    list: () => insuranceBrokersRoute.list.build(),
    show: (id) => insuranceBrokersRoute.show.build({id}),
    new:  (id) => insuranceBrokersRoute.new.build({id})
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      INSURANCE_BROKERS_FETCH_START,
    FETCH_DONE:       INSURANCE_BROKERS_FETCH_DONE,
    FETCH_LIST_START: INSURANCE_BROKERS_FETCH_LIST_START,
    FETCH_LIST_DONE:  INSURANCE_BROKERS_FETCH_LIST_DONE,
    CHANGE_LIST:      INSURANCE_BROKERS_CHANGE_LIST,
    CHANGE_CURRENT:   INSURANCE_BROKERS_SET_CURRENT,
    SET_ERRORS:       INSURANCE_BROKERS_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_INSURANCE_BROKERS_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_INSURANCE_BROKERS_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_INSURANCE_BROKERS_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_INSURANCE_BROKERS_REQUEST, crudActions.createAction),
  takeLatest(FETCH_INSURANCE_BROKERS_REQUEST, crudActions.fetchListAction),
]);