import Request from '@src/services/request'
import queryStringParser from '@src/utils/queryStringParser'

import { sharedDocumentsApi } from './sharedDocuments.config'

export const findRecordRequest = (id, args={}) => {
  return Request.get( `${ sharedDocumentsApi.show.build({id}) }?${queryStringParser(args)}` )
}

export const fetchListRecordsRequest = (args={}, requestSource) => {
  return Request.get( `${ sharedDocumentsApi.list.build() }?${queryStringParser(args)}`, {
    cancelToken: requestSource?.token
  } )
}

export const destroyRecordRequest = (id, args={}) => {
  return Request.del( sharedDocumentsApi.destroy.build({id}), args )
}

export const updateRecordRequest = (id, data={}, args={} ) => {
  return Request.put( `${ sharedDocumentsApi.update.build({id}) }?${queryStringParser(args)}`, data )
}

export const createRecordRequest = (data={}, args={}) => {
  return Request.post( `${ sharedDocumentsApi.create.build() }?${queryStringParser(args)}`, data )
}
