import React, { useMemo } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import MuiAppBar from '@material-ui/core/AppBar';
import makeTheme from '~/theme/makePrimaryColorTheme'

const AppBar = ({color, ...props}) => {
  const theme = useMemo(() => makeTheme(color));
  return <ThemeProvider theme={theme}>
    <MuiAppBar {...props} color='primary'/>
  </ThemeProvider>
}

export default React.memo(AppBar)