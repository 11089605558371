import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import { takeLatest, all } from 'redux-saga/effects';
import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from '@src/services/fetchApi/cities';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';
import { citiesRoute } from '@src/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const CITIES_SET_CURRENT =       '@App/cities/CITIES_SET_CURRENT';
export const CITIES_FETCH_START =       '@App/cities/CITIES_FETCH_START';
export const CITIES_FETCH_DONE  =       '@App/cities/CITIES_FETCH_DONE';
export const CITIES_FETCH_LIST_START =  '@App/cities/CITIES_FETCH_LIST_START';
export const CITIES_FETCH_LIST_DONE =   '@App/cities/CITIES_FETCH_LIST_DONE';
export const CITIES_SET_ERRORS =        '@App/cities/CITIES_SET_ERRORS';
export const CLEAR_STATE =                  '@App/cities/CLEAR_STATE';
export const FIND_CITY_REQUEST =        '@App/cities/FIND_CITY_REQUEST';
export const DESTROY_CITY_REQUEST =   '@App/cities/DESTROY_CITIES_REQUEST';
export const UPDATE_CITIES_REQUEST =    '@App/cities/UPDATE_CITIES_REQUEST';
export const CREATE_CITIES_REQUEST =    '@App/cities/CREATE_CITIES_REQUEST';
export const FETCH_CITIES_REQUEST  =    '@App/cities/FETCH_CITIES_REQUEST';
export const CITIES_CHANGE_LIST  =      '@App/cities/CITIES_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [CITIES_FETCH_START]: fetchStart,
    [CITIES_FETCH_DONE]:  fetchDone,
    [CITIES_FETCH_LIST_START]: fetchListStart,
    [CITIES_FETCH_LIST_DONE]: fetchListDone,
    [CITIES_SET_ERRORS]: setErrors,
    [CITIES_SET_CURRENT]: setCurrent,
    //[DESTROY_CITIES_REQUEST]: initLoading,
    [CREATE_CITIES_REQUEST]: initLoading,
    [UPDATE_CITIES_REQUEST]: initLoading,
    [CITIES_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}, callbackSuccess) {
  return {
    type: FIND_CITY_REQUEST,
    payload: { id, params, callbackSuccess }
  }
}
// export function destroyRecord(id, callbackSuccess) {
//   return {
//     type: DESTROY_CITIES_REQUEST,
//     payload: { id, callbackSuccess }
//   }
// }

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_CITIES_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_CITIES_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_CITIES_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

export function setCurrentRecord(current) {
  return {
    type: CITIES_SET_CURRENT,
    payload: { current }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'cities',

  routes: {
    list: () => citiesRoute.list.build(),
    show: (id) => citiesRoute.show.build({id}),
    new: () => citiesRoute.new.build(),
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      CITIES_FETCH_START,
    FETCH_DONE:       CITIES_FETCH_DONE,
    FETCH_LIST_START: CITIES_FETCH_LIST_START,
    FETCH_LIST_DONE:  CITIES_FETCH_LIST_DONE,
    CHANGE_LIST:      CITIES_CHANGE_LIST,
    CHANGE_CURRENT:   CITIES_SET_CURRENT,
    SET_ERRORS:       CITIES_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_CITY_REQUEST, crudActions.showAction),
  //takeLatest(DESTROY_CITIES_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_CITIES_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_CITIES_REQUEST, crudActions.createAction),
  takeLatest(FETCH_CITIES_REQUEST, crudActions.fetchListAction),
]);
