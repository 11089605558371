import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import {ToastContainer} from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import OneSignal from 'react-onesignal';

import ReduxProvider from '~/providers/ReduxProvider';
import ThemeProvider from '~/providers/ThemeProvider';

import Main from './Main';
import history from '@src/services/history';
import { persistor } from '~/store';
import { PageTitleContextProvider } from '~/contexts/PageTitleContext'


function App() {

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID
    });
  }, []);

  return (
    <PageTitleContextProvider>
      <ReduxProvider>
        <PersistGate persistor={persistor}>
          <CssBaseline />
          <ThemeProvider>
            <Router history={history}>
              <Main />
              <ToastContainer autoClose={5000} />
            </Router>
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    </PageTitleContextProvider>
  );
}

export default App;
