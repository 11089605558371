import Route from '@src/services/route'

export const singularName = 'Importação'
export const pluralName = 'Importações'
export const permissionKeyCode = 'SpreadsheetImport'

export const spreadsheetImportsApi = {
  show:    new Route('/api/v1/admin/spreadsheet_imports/:id'),
  destroy: new Route('/api/v1/admin/spreadsheet_imports/:id'),
  create:  new Route('/api/v1/admin/spreadsheet_imports'),
  list:    new Route('/api/v1/admin/spreadsheet_imports')
}

export const spreadsheetImportsRoute = {
  list:  new Route('/sys/importacao_planilhas'),
  show:  new Route('/sys/importacao_planilhas/:id'),
  clone: new Route('/sys/importacao_planilhas/new/:id'),
  new:   new Route('/sys/importacao_planilhas/new')
}

export const defaultFindOneIncludes = 'cooperative-spreadsheet'