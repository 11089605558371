import axios from 'axios';
import Auth from './auth';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.meuapp.com'

const HTTP_METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DEL: 'delete'
};

const HTTP_STATUS = {
  OK: 200,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422
};

const api = axios.create({
  baseURL: BASE_URL
});

api.interceptors.response.use(
  response => response,
  error => {
    const response = error.response;

    return Promise.reject({
      status: response.status,
      name: response.data.error,
      description: response.data.error_description
    });
  }
);

class Request {
  static HTTP_STATUS = HTTP_STATUS;

  static CancelToken = axios.CancelToken;

  static isCancel = axios.isCancel;

  static setHeader(name, value) {
    api.defaults.headers[name] = value;
  }

  static get(path, config = {}) {
    return Request._request({ method: HTTP_METHOD.GET, path, config });
  }

  static post(path, data, config = {}) {
    return Request._request({ method: HTTP_METHOD.POST, path, data, config });
  }

  static put(path, data) {
    return Request._request({ method: HTTP_METHOD.PUT, path, data });
  }

  static patch(path, data) {
    return Request._request({ method: HTTP_METHOD.PATCH, path, data });
  }

  static del(path, config = {}) {
    return Request._request({ method: HTTP_METHOD.DEL, path, config });
  }

  static async _request({ method, path, data = {}, config = {} }) {
    try {
      const response = await Request._httpRequest(method, path, data, config);

      return response;
    } catch(err) {
      const requestIsCanceled = !err.response;
      if (requestIsCanceled) {
        return Promise.reject(err);
      }

      const { status, data: errData } = err.response;

      if (
        status === Request.HTTP_STATUS.UNAUTHORIZED
      ) {
        const newToken = await Auth.fetchRefreshToken()

        if (newToken) {
          Request.setHeader(
            'Authorization',
            `Bearer ${newToken}`
          );
          return await Request._httpRequest(method, path, data, config);
        }
      }

      return Promise.reject({ status, ...errData });
    }
  }

  static async _httpRequest(method, path, data, config) {
    const axiosMethod = axios[method];
    const reqConfig = Request._getConfig(config);

    if ([HTTP_METHOD.GET, HTTP_METHOD.DEL].includes(method)) {
      return await axiosMethod(path, reqConfig);
    }

    return await axiosMethod(path, data, reqConfig);
  }

  static _getConfig(config) {
    return {
      baseURL: BASE_URL,
      responseType: 'json',
      headers: {
        Authorization: api.defaults.headers.Authorization,
        'Content-Type': 'application/json'
      },
      ...config
    };
  }
}

export default Request;
