import Route from '@src/services/route'

export const singularName = 'Documento'
export const pluralName = 'Documentos'
export const permissionKeyCode = 'SharedDocument'

export const sharedDocumentsApi = {
  show:    new Route('/api/v1/shared_documents/:id'),
  update:  new Route('/api/v1/shared_documents/:id'),
  destroy: new Route('/api/v1/shared_documents/:id'),
  create:  new Route('/api/v1/shared_documents'),
  list:    new Route('/api/v1/shared_documents')
}

export const sharedDocumentsRoute = {
  list:  new Route('/sys/documentos'),
  show:  new Route('/sys/documentos/:id'),
  clone: new Route('/sys/documentos/new/:id'),
  new:   new Route('/sys/documentos/new'),
  edit:  new Route('/sys/documentos/:id/edit')
}

export const defaultFindOneIncludes = 'cooperative-sub_cooperative-producer-attachment'