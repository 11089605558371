import { combineReducers } from 'redux';

import crud from './modules/crud';
import notifications from './modules/notifications';
import forgotPassword from './modules/forgotPassword';
import resetPassword from './modules/resetPassword';
import userProfile from './modules/userProfile';
import auth from './modules/auth';
import users from './modules/users';
import fields from './modules/fields';
import people from './modules/people';
import producers from './modules/producers';
import roles from './modules/roles';
import metadata from './modules/metadata';
import customRecords from './modules/customRecords';
import cities from './modules/cities';
import cooperatives from './modules/cooperatives';

import subCooperatives from '~/modules/subCooperatives/subCooperatives.redux';
import technicians from '~/modules/technicians/technicians.redux';
import techinicianManagers from '~/modules/techinicianManagers/techinicianManagers.redux';
import insuranceBrokers from '~/modules/insuranceBrokers/insuranceBrokers.redux';
import properties from '~/modules/properties/properties.redux';
import sharedDocuments from '~/modules/sharedDocuments/sharedDocuments.redux';
import spreadsheetImports from '~/modules/spreadsheetImports/spreadsheetImports.redux';

export default combineReducers({
  crud,
  notifications,
  auth,
  userProfile,
  people,
  producers,
  roles,
  metadata,
  forgotPassword,
  resetPassword,
  users,
  fields,
  customRecords,
  cooperatives,
  cities,
  subCooperatives,
  technicians,
  techinicianManagers,
  insuranceBrokers,
  sharedDocuments,
  spreadsheetImports,
  properties
});
