import SchemaBuilder from './SchemaBuilder';
import fieldKindEnum from '@src/enums/fieldKindEnum';

const hidden = {hidden: true, tableView: false}

const columns = {
  id:            { type: 'integer',  title: 'ID' },
  name:          { type: 'string',   title: 'Nome', width: 200 },
  kind:          {
    type: 'enum',
    title: 'Tipo',
    default: 'string',
    width: 150,
    _enum_: fieldKindEnum
  },
  key:           { type: 'string',   title: 'Chave', width: 150 },
  min_value:     { type: 'numeric',  title: 'Valor mínimo', ...hidden },
  max_value:     { type: 'numeric',  title: 'Valor máximo', ...hidden },
  record_type:   { type: 'string',   title: 'Tipo registro', ...hidden, isFilter: false},
  size_value:    { type: 'integer',  title: 'Tamanho', ...hidden},
  description:   { type: 'string',   title: 'Descrição', ...hidden},
  options:       { type: 'array',    title: 'Opções', ...hidden, isFilter: false},
  apply_editor:  { type: 'boolean',  title: 'Aplicar editor', default: false, ...hidden},
  allow_other:   { type: 'boolean',  title: 'Permite outro valor', default: false, ...hidden},
  created_at:    { type: 'datetime', default: '', title: 'Criado em', width: 150 },
  updated_at:    { type: 'datetime', default: '', title: 'Atualizado em', ...hidden }
};

const fieldSchema = new SchemaBuilder( columns );

export default fieldSchema;