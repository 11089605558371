import Request from '@src/services/request'
import queryStringParser from '@src/utils/queryStringParser'

import { insuranceBrokersApi } from './insuranceBrokers.config'

export const findRecordRequest = (id, args={}) => {
  return Request.get( `${ insuranceBrokersApi.show.build({id}) }?${queryStringParser(args)}` );
};

export const fetchListRecordsRequest = (args={}, requestSource) => {
  return Request.get( `${ insuranceBrokersApi.list.build() }?${queryStringParser(args)}`, {
    cancelToken: requestSource?.token
  } );
};

export const destroyRecordRequest = (id, args={}) => {
  return Request.del( insuranceBrokersApi.destroy.build({id}), args );
};

export const updateRecordRequest = (id, data={}, args={} ) => {
  return Request.put( `${ insuranceBrokersApi.update.build({id}) }?${queryStringParser(args)}`, data );
};

export const createRecordRequest = (data={}, args={}) => {
  return Request.post( `${ insuranceBrokersApi.create.build() }?${queryStringParser(args)}`, data );
};

export const insuranceBrokersFetch = (inputValue, page, doneCallback) => {
  return Request.get(`/api/v1/search/insurance_brokers?qs=${inputValue}&page=${page}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data.map( e => ({
          id: e.id,
          name: e.name,
        }))
      }
    )
  })
  .catch(err => err);
}

export const insuranceBrokersShow =  (id) => {
  return Request.get(`/api/v1/search/insurance_brokers/${id}`).then(resp => {
    if(resp.data && resp.data.data){
      return resp.data.data
    }

    return { id: null, name: 'Não localizado' };
  })
  .catch(err => err);
}