import { useEffect, useContext } from 'react';
import isPresent from '@src/utils/isPresent'

import PageTitleContext from '~/contexts/PageTitleContext'

export default function Page({title, children}){
  const { setTitle, resetToDefault} = useContext(PageTitleContext);

  useEffect(() => {
    resetToDefault()
    if( isPresent(title) ) {
      setTimeout( () => setTitle(title), 100 )
    }
  }, [title])

  return children
}