import Request from '@src/services/request';
import { rolesApi } from '@src/routes/api';
import queryStringParser from '@src/utils/queryStringParser';

export const findRecordRequest = (id, args={}) => {
  return Request.get( `${ rolesApi.show.build({id}) }?${queryStringParser(args)}` );
};

export const fetchListRecordsRequest = (args={}, requestSource) => {
  return Request.get( `${ rolesApi.list.build() }?${queryStringParser(args)}`, {
    cancelToken: requestSource?.token
  } );
};

export const destroyRecordRequest = (id, args={}) => {
  return Request.del( rolesApi.destroy.build({id}), args );
};

export const updateRecordRequest = (id, data={}, args={} ) => {
  return Request.put( `${ rolesApi.update.build({id}) }?${queryStringParser(args)}`, data );
};

export const createRecordRequest = (data={}, args={}) => {
  return Request.post( `${ rolesApi.create.build() }?${queryStringParser(args)}`, data );
};