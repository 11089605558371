import React, { useMemo } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import MuiTypography from '@material-ui/core/Typography';
import makeTheme from '~/theme/makePrimaryColorTheme'
import PropTypes from 'prop-types';

import { PALETTE_COLORS, TYPOGRAPHY_PALETTE_COLORS } from '@src/config/constants'

const Typography = ({color, ...props}) => {
  if( TYPOGRAPHY_PALETTE_COLORS.includes(color) ){
    return <MuiTypography {...props} color={color}/>
  }

  const theme = useMemo(() => makeTheme(color));
  return <ThemeProvider theme={theme}>
    <MuiTypography {...props} color='primary'/>
  </ThemeProvider>
}


Typography.defaultProps = {
  ...MuiTypography.defaultProps,
  color: 'initial',
};

Typography.propTypes = {
  ...MuiTypography.propTypes,
  color: PropTypes.oneOf([...PALETTE_COLORS, ...TYPOGRAPHY_PALETTE_COLORS]),
};

export default React.memo(Typography)