/* eslint no-unused-expressions: "off" */
import produce from 'immer';

const createReducer = (initial, types) =>
  (state = initial, {type, ...action}) =>
    produce(state, draft => {
      type in types
        ? types[type](draft, action, state)
        : state;
    } )

export default createReducer