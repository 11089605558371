import { all } from 'redux-saga/effects';

import {saga as forgotPassword} from './modules/forgotPassword';
import {saga as resetPassword} from './modules/resetPassword';
import {saga as userProfile} from './modules/userProfile';
import {saga as auth} from './modules/auth';
import {saga as crud} from './modules/crud';
import {saga as fields} from './modules/fields';
import {saga as people} from './modules/people';
import {saga as producers} from './modules/producers';
import {saga as metadata} from './modules/metadata';
import {saga as customRecords} from './modules/customRecords';
import {saga as roles} from './modules/roles';
import {saga as users} from './modules/users';
import {saga as cities} from './modules/cities';
import {saga as cooperatives} from './modules/cooperatives';
import {saga as subCooperatives} from '~/modules/subCooperatives/subCooperatives.redux';
import {saga as technicians} from '~/modules/technicians/technicians.redux';
import {saga as techinicianManagers} from '~/modules/techinicianManagers/techinicianManagers.redux';
import {saga as insuranceBrokers} from '~/modules/insuranceBrokers/insuranceBrokers.redux';
import {saga as properties} from '~/modules/properties/properties.redux';
import {saga as sharedDocuments} from '~/modules/sharedDocuments/sharedDocuments.redux';
import {saga as spreadsheetImports} from '~/modules/spreadsheetImports/spreadsheetImports.redux';

export default function* rootSaga() {
  return yield all([
    crud,
    auth,
    userProfile,
    people,
    producers,
    metadata,
    roles,
    forgotPassword,
    resetPassword,
    users,
    fields,
    cities,
    cooperatives,
    subCooperatives,
    customRecords,
    technicians,
    techinicianManagers,
    insuranceBrokers,
    properties,
    sharedDocuments,
    spreadsheetImports
  ]);
}
