import AdministrationIcon from '@material-ui/icons/AttachMoney'
import DashboardIcon from '@material-ui/icons/Dashboard'
// import DescriptionIcon from '@material-ui/icons/Description'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import InputIcon from '@material-ui/icons/Input'
import LocationCityIcon from '@material-ui/icons/LocationCity'
// import PeopleIcon from '@material-ui/icons/People'
import PermissionIcon from '@material-ui/icons/Lock'
import StorageIcon from '@material-ui/icons/Storage'
import UserIcon from '@material-ui/icons/Person'
import ApartmentIcon from '@material-ui/icons/Apartment'
import BusinessIcon from '@material-ui/icons/Business'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import DescriptionIcon from '@material-ui/icons/Description';
import PublishIcon from '@material-ui/icons/Publish';

import {
  citiesRoute,
  dashboardRoute,
  fieldsRoute,
  metadataRoute,
  rolesRoute,
  usersRoute,
  cooperativesRoute,
  producersRoute
} from '@src/routes'

import { pluralName as producerName } from '@src/records/ProducerRecord'
import {
  pluralName as subCooperativeName,
  // permissionKeyCode as subCooperativeKeyCode,
  subCooperativesRoute
} from '~/modules/subCooperatives/subCooperatives.config'

import {
  pluralName as technicianName,
  // permissionKeyCode as technicianKeyCode,
  techniciansRoute
} from '~/modules/technicians/technicians.config'

import {
  pluralName as techinicianManagerName,
  // permissionKeyCode as techinicianManagerKeyCode,
  techinicianManagersRoute
} from '~/modules/techinicianManagers/techinicianManagers.config'

import {
  pluralName as insuranceBrokerName,
  // permissionKeyCode as insuranceBrokerKeyCode,
  insuranceBrokersRoute
} from '~/modules/insuranceBrokers/insuranceBrokers.config'

import {
  pluralName as propertyName,
  permissionKeyCode as propertyKeyCode,
  propertiesRoute
} from '~/modules/properties/properties.config'

import {
  pluralName as sharedDocumentName,
  permissionKeyCode as sharedDocumentKeyCode,
  sharedDocumentsRoute
} from '~/modules/sharedDocuments/sharedDocuments.config'

import {
  pluralName as spreadsheetImportName,
  permissionKeyCode as spreadsheetImportKeyCode,
  spreadsheetImportsRoute
} from '~/modules/spreadsheetImports/spreadsheetImports.config'

export const drawerMenuAvatar = {
  name: 'Halley Specter',
  roleName: 'Admin',
  url: 'https://66.media.tumblr.com/3e3f627161fb69b617504b320b57d480/tumblr_pojtnodtMz1rfd9dz_640.jpg',
  onClick: () => {},
}

const drawerMenuItems = [
  {
    name: 'Dashboard',
    icon: DashboardIcon,
    keyCode: 'Dashboard',
    to: dashboardRoute.self.path
  },
  {
    name: producerName,
    icon: PersonPinIcon,
    keyCode: 'Producer',
    to: producersRoute.list.path
  },
  {
    name: propertyName,
    icon: HomeWorkIcon,
    keyCode: propertyKeyCode,
    to: propertiesRoute.list.path
  },
  {
    name: 'Administração',
    icon: AdministrationIcon,
    collapse: [
      {
        name: 'Cooperativas',
        icon: ApartmentIcon,
        keyCode: 'Admin',
        to: cooperativesRoute.list.path
      },
      {
        name: subCooperativeName,
        icon: BusinessIcon,
        keyCode: 'Admin',
        to: subCooperativesRoute.list.path
      },
      {
        name: technicianName,
        icon: RecordVoiceOverIcon,
        keyCode: 'Admin',
        to: techniciansRoute.list.path
      },
      {
        name: techinicianManagerName,
        icon: SupervisorAccountIcon,
        keyCode: 'Admin',
        to: techinicianManagersRoute.list.path
      },
      {
        name: insuranceBrokerName,
        icon: SupervisorAccountIcon,
        keyCode: 'Admin',
        to: insuranceBrokersRoute.list.path
      },
      {
        name: sharedDocumentName,
        icon: DescriptionIcon,
        keyCode: sharedDocumentKeyCode,
        to: sharedDocumentsRoute.list.path
      },
      {
        name: spreadsheetImportName,
        icon: PublishIcon,
        keyCode: spreadsheetImportKeyCode,
        to: spreadsheetImportsRoute.list.path
      },
      {
        name: 'Permissões',
        icon: PermissionIcon,
        keyCode: 'Admin',
        to: rolesRoute.list.path
      }
    ]
  },
  {
    name: 'Master',
    icon: DragIndicatorIcon,
    collapse: [
      {
        name: 'Usuários',
        icon: UserIcon,
        keyCode: 'User',
        onlyMaster: true,
        to: usersRoute.list.path
      },
      {
        name: 'Metadados',
        icon: StorageIcon,
        keyCode: 'Metadata',
        onlyMaster: true,
        to: metadataRoute.list.path
      },
      {
        name: 'Campos',
        icon: InputIcon,
        keyCode: 'Field',
        onlyMaster: true,
        to: fieldsRoute.list.path
      },
      {
        name: 'Cidades',
        onlyMaster: true,
        icon: LocationCityIcon,
        keyCode: 'City',
        to: citiesRoute.list.path
      }
    ]
  }
]


export default drawerMenuItems
